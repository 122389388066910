<template>
  <v-data-table class="elevation-2" :items="users" :headers="usersTableHeaders" sort-by="username">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">Group Members</v-toolbar-title>
        <v-spacer/>
        <v-dialog max-width=400 v-model="showEmailInvite">
          <template v-slot:activator="{ on }">
            <v-btn class="text-none text-body-1" v-on="on">Invite User</v-btn>
          </template>
          <v-card class="px-7 py-4">
            <v-col class="pa-0" v-if="emailInviteStatus === ''" key="showInvite">
              <v-row class="text-h5">Invite User to Group</v-row>
              <v-row><v-text-field label="Enter email address to send invite" v-model="inviteeEmail"/></v-row>
              <v-row justify='end'><v-btn @click="inviteUser()" :disabled="inviteeEmail.length < 1" :loading="inviteLoading" class="text-body-1 text-none">Send Invite</v-btn></v-row>
            </v-col>
            <v-row v-else-if="emailInviteStatus === 'success'" key="inviteSuccess" class="px-0 py-3 text-h5" align='center' justify='center'>
              Invite Email Sent <v-icon large color="primary" class="ml-2">check</v-icon>
            </v-row>
            <v-row v-else-if="emailInviteStatus === 'failure'" key="inviteFailed" class="px-0 py-3 text-h5" align='center' justify='center'>
              Invite Email Failed: {{ errorMessage }}
            </v-row>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.role`]="{ item }">
      {{ item.super_admin ? 'Super Admin' : '' }}
    </template>
    <template v-slot:[`item.manage`]="{ item }">
      <ManageUser v-on:message="$emit('message', $event)" v-on:adminstatuschange="item.super_admin = $event" :user="item"/>
    </template>
  </v-data-table>
</template>
<script>
import { http } from '@/plugins/axios'
import ManageUser from '@/components/groups/ManageUser'

export default {
  components: {
    ManageUser
  },
  data () {
    return {
      inviteeEmail: '',
      emailInviteStatus: '',
      inviteLoading: false,
      showEmailInvite: false,
      usersTableHeaders: [
        { 'text': 'Username', 'value': 'username' },
        { 'text': 'Role', 'value': 'role' },
        { 'text': 'Manage User', 'value': 'manage', 'align': 'end' }
      ],
      errorMessage: ''
    }
  },
  props: {
    users: Array
  },
  watch: {
    showEmailInvite () {
      this.inviteeEmail = ''
      this.emailInviteStatus = ''
    }
  },
  methods: {
    inviteUser () {
      this.inviteLoading = true
      let path = '/group/invite'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = JSON.stringify({ 'username': this.inviteeEmail })
      http.post(path, data, { headers: headers })
        .then(response => { this.emailInviteStatus = 'success' })
        .catch(error => {
          this.errorMessage = error.response.data.error
          this.emailInviteStatus = 'failure'
        })
        .then(() => {
          this.inviteLoading = false
          setTimeout(() => { this.showEmailInvite = false }, 3000)
        })
    },
    removeUser (username) {
      let path = '/group/user/remove/' + username
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.delete(path, { headers: headers })
        .then(response => { console.log(response) })
        .catch(error => { console.log('Error removing user from group: ' + error.message) })
    }
  }
}
</script>
<style scoped>
.row >>> .v-input--selection-controls {
  margin-top: 0;
}
</style>
