import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: {
      variations: false,
      customProperties: true
    },
    themes: {
      light: {
        primary: '#6BBF4E',
        anchor: '#1976d2',
        background: '#FFFFFF',
        textcolor: '#000000'
      },
      dark: {
        primary: '#37474F',
        anchor: '#1976d2',
        background: '#3b3b3b'
      }
    }
  }
})
