<template>
  <v-stepper class="pa-3" v-model="signInStep">
    <MessageToast ref="messageToast"/>
    <v-stepper-items>
      <v-stepper-content step=1>
        <v-col class="py-0">
          <v-row class="text-h4 mb-4">Login to CRXcavator</v-row>
          <v-row>
            <v-text-field prepend-icon="person" label="Email Address" v-model="userEmail"/>
          </v-row>
          <v-row>
            <v-text-field prepend-icon="vpn_key" label="Password" v-model="userPassword" type="password"/>
          </v-row>
          <v-row justify='end' class="mb-2">
            <v-btn :disabled="userEmail.length < 1 || userPassword.length < 1" :loading="signInLoading" @click='signIn()' class="text-none white--text text-body-1 font-weight-bold" color="primary" large>Login</v-btn>
          </v-row>
          <v-row align='center' justify='space-between'>
            <v-btn @click="$store.commit('storeAuthStatus', { authStatus: 'SIGN_UP' })" class="text-none text-body-1" large>Create an Account</v-btn>
            <v-btn @click="$store.commit('storeAuthStatus', { authStatus: 'FORGOT_PASSWORD' })" class="text-decoration-underline text-none text-body-1 blue--text px-0" text large>Forgot Password</v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=2>
        <DuoAuth v-if="signInStep === 2" v-on:duo-authed="duoAuthed()"/>
      </v-stepper-content>
      <v-stepper-content step=3>
        <v-row class="px-0 py-3 text-h5" align='center' justify='center'>Login successful <v-icon large color="primary" class="ml-2">check</v-icon></v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { Auth } from '@aws-amplify/auth'
import DuoAuth from '@/components/cognito/DuoAuth'

export default {
  components: {
    DuoAuth
  },
  data () {
    return {
      userPassword: '',
      userEmail: '',
      user: null,
      signInStep: 1,
      signInLoading: false
    }
  },
  methods: {
    async signIn () {
      this.signInLoading = true
      try {
        Auth.signIn(this.userEmail, this.userPassword)
          .then(user => {
            console.log(user)
            this.$store.commit('storeCognitoUser', { user: user })
            this.signInStep = 2
          })
          .catch(err => {
            this.$refs.messageToast.showMessage(err.message)
          })
          .then(() => { this.signInLoading = false })
      } catch (error) {
        this.signInLoading = false
        console.log('error signing in:', error)
      }
    },
    duoAuthed () {
      this.signInStep = 3
      this.$emit('signed-in')
      this.userPassword = ''
      setTimeout(() => { this.signInStep = 1 }, 5000)
    }
  }
}
</script>
