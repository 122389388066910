<template>
  <v-data-table
    class="elevation-2"
    no-data-text="No extension requests found for your group"
    :items="extensionRequests"
    :headers="headers"
    :loading="loading"
    :search="search">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">Extension Requests</v-toolbar-title>
        <v-spacer/>
        <v-btn @click="showClosedRequests = !showClosedRequests" class="text-none mr-4">{{ showClosedRequests ? 'Hide' : 'Show' }} Closed Requests</v-btn>
        <v-text-field
          v-model="search"
          append-icon="search"
          hide-details
          label="Search requests"
          single-line
          style="max-width: 424px;"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-row class="mx-0" align="center" justify="start" @click="viewExtension(item.extension_id)">
        <img height="40px" width="40px" class="mr-2" :src="item.icon"/>
        {{ item.name }}
      </v-row>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-row class="mx-0" justify='center'>
        <v-btn dark color="green" class="text-none px-2 mr-3" @click='approveExtensionRequest(item)'>
          Approve <v-icon>check</v-icon>
        </v-btn>
        <v-btn dark color="red darken-2" class="text-none px-2 mr-3" @click='denyExtensionRequest(item)'>
          Deny <v-icon>close</v-icon>
        </v-btn>
        <v-dialog max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-none px-2 haswhitespace" v-bind="attrs" v-on="on">
              View Request <v-icon>visibility</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-2">
            <v-col class="px-6">
              <v-row class="text-overline">Requested Extension</v-row>
              <v-row class="text-h6 mb-4">{{ item.name }}</v-row>
              <v-row class="text-overline">Extension ID</v-row>
              <v-row class="text-h6 mb-4">{{ item.extension_id }}</v-row>
              <v-row class="text-overline">Requestor</v-row>
              <v-row class="text-h6 mb-4">{{ item.username }}</v-row>
              <v-row class="text-overline">Business Justification</v-row>
              <v-row class="text-h6 mb-4">"{{ item.business_justification }}"</v-row>
              <v-row>
                <v-btn class="px-4 py-1 text-none" color="grey lighten-4" target="_blank" :href="'/report/' + item.extension_id">
                <!-- THE ABOVE HREF WILL NEED TO BE CHANGED WHEN WE SWAP TO HISTORY MODE -->
                  View Extension Report<v-icon class="ml-1" small>open_in_new</v-icon>
                </v-btn>
              </v-row>
              <v-divider class="my-4"/>
              <v-row justify='end'>
                <v-btn dark color="red darken-2" class="mr-4 px-2 text-none" @click='denyExtensionRequest(item)'>
                  Deny <v-icon>close</v-icon>
                </v-btn>
                <v-btn dark color="green" class="px-2 text-none" @click='approveExtensionRequest(item)'>
                  Approve <v-icon>check</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-data-table>
</template>
<script>
import { http } from '@/plugins/axios'

// TO DO
// Filter by request status

export default {
  data () {
    return {
      extensionRequests: [],
      headers: [
        { text: 'Extension Name', value: 'name' },
        { text: 'Requestor', value: 'username' },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            return value === 'Requested' || this.showClosedRequests
          }
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      search: '',
      loading: true,
      showClosedRequests: false
    }
  },
  methods: {
    getExtensionRequests () {
      let path = '/group/extensions/request'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get(path, { headers: headers })
        .then(response => {
          this.extensionRequests = response.data || []
        })
        .catch(error => console.log(error))
        .then(() => { this.loading = false })
    },
    approveExtensionRequest (request) {
      let path = '/group/extensions/request/' + request.id + '/Approved'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.put(path, null, { headers: headers })
        .then(response => this.$emit('message', ['Extension request approved', 'success']))
        .catch(error => this.$emit('message', ['Error approving request: ' + error.message, 'error']))
    },
    denyExtensionRequest (request) {
      let path = '/group/extensions/request/' + request.id + '/Denied'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.put(path, null, { headers: headers })
        .then(response => this.$emit('message', ['Extension request denied', '']))
        .catch(error => this.$emit('message', ['Error denying request: ' + error.message, 'error']))
    },
    viewExtension (extensionID) {
      this.$router.push({ path: '/report/' + extensionID })
    }
  },
  mounted () {
    this.getExtensionRequests()
  }
}
</script>
<style scoped>
.v-application >>> .text-overline {
  line-height: 1rem;
}
</style>
