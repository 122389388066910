<template>
  <v-row class="mx-0">
    <v-col cols=3>
      <v-dialog max-width="500px" v-model="dialog">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon><v-icon large class="grey--text">info_outline</v-icon></v-btn>
        </template>
        <v-card class="pa-4">
          <v-row align='center'>
            <v-col cols=2 class="pt-0">
              <v-icon @click="dialog = false">close</v-icon>
            </v-col>
            <v-col cols=8 class="text-h5 text-center pt-0">
              <v-html>{{ titleText }}</v-html>
            </v-col>
          </v-row>
          <!-- <v-row style="position: absolute"><v-icon @click="dialog = false">close</v-icon></v-row>
          <v-row class="text-h5 justify-center">{{ titleText }}</v-row> -->
          <v-row>
            <v-col class="py-0">
              <slot name="info-text">
                {{ infoText }}
              </slot>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols=6>
      <v-row justify="center" align="center" class="headline text-center" style="height: 100%;">{{ titleText }}</v-row>
    </v-col>
    <v-col cols=3>
      <slot></slot>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  props: {
    titleText: {
      type: String,
      default: ''
    },
    infoText: {
      type: String,
      default: ''
    }
  }
}
</script>
