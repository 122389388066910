<template>
  <v-container fluid class="pt-3">
    <MessageToast ref="messageToast"/>
    <v-row class="mx-0">
      <v-col cols=2 style="position: fixed;" class="d-none d-lg-block">
        <Scrollspy ref="scrollspy" :elements="scrollspyElements" />
      </v-col>
      <v-col cols=12 lg=10 offset-lg=2>
        <v-row>
          <v-col class="pt-0">
            <v-card>
              <v-row>
                <v-col>
                  <v-row justify='center'>Your organization has</v-row>
                  <v-row justify='center' class='text-h3'>{{ allowedExtensionCount }}</v-row>
                  <v-row justify='center' class='text-center'>allowed extensions</v-row>
                </v-col>
                <v-col>
                  <v-row justify='center'>The average risk score is</v-row>
                  <v-row justify='center' class='text-h3'>{{ avgRiskScore }}</v-row>
                  <v-row justify='center' class='text-center'>for the extensions allowed in your organization</v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row id="allowedExtensions" v-intersect.quiet="onIntersect">
          <v-col>
            <AllowedExtensions
              :allowedExtensionCount.sync="allowedExtensionCount"
              :avgRiskScore.sync="avgRiskScore"
              :groupExtensions="groupData.extensions"
              v-if="groupData.extensions"
              v-on:message="$refs.messageToast.showMessage(...$event)"/>
          </v-col>
        </v-row>
        <v-row id="extensionRequests" v-intersect.quiet="onIntersect">
          <v-col>
            <ExtensionRequests v-on:message="$refs.messageToast.showMessage(...$event)"/>
          </v-col>
        </v-row>
        <v-row id="userManagement" v-intersect.quiet="onIntersect">
          <v-col>
            <GroupUsers :users="groupData.users || []" v-on:message="$refs.messageToast.showMessage(...$event)"/>
          </v-col>
        </v-row>
        <v-row id="embeddedDashboards" v-intersect.quiet="onIntersect">
          <v-col>
            <EmbeddedDashboards v-on:message="$refs.messageToast.showMessage(...$event)"/>
          </v-col>
        </v-row>
        <v-row id="dataRemoval" v-intersect.quiet="onIntersect">
          <v-col>
            <DataRemoval v-on:message="$refs.messageToast.showMessage(...$event)"/>
          </v-col>
        </v-row>
        <v-row id="groupSettings" v-intersect.quiet="onIntersect">
          <v-col>
            <v-card class="px-8 py-5">
              <v-row class="text-h4">Group Settings</v-row>
              <v-row class="text-h6 py-3" align='center'>
                Group Secret<v-text-field readonly :append-icon="secretHidden ? 'visibility' : 'visibility_off'" @click:append="secretHidden = !secretHidden" :type="secretHidden ? 'password' : 'text'" class="ml-3 mt-0 pt-0" hide-details style="max-width: 424px;" v-model="groupData.secret"/>
              </v-row>
              <v-row>
                <v-btn class="text-none text-body-1" @click="resetGroupSecret()">{{ groupData.secret ? 'Reset' : 'Create' }} Group Secret</v-btn>
              </v-row>
              <v-divider class="my-3"/>

              <v-row class="text-h5">Webhook</v-row>
              <v-row class="text-h6 py-3" align='center'>
                Webhook secret <v-text-field readonly :append-icon="webhookSecretHidden ? 'visibility' : 'visibility_off'" @click:append="webhookSecretHidden = !webhookSecretHidden" :type="webhookSecretHidden ? 'password' : 'text'" class="ml-3 mt-0 pt-0" hide-details style="max-width: 424px;" :value="groupData.webhook_secret"/>
              </v-row>
              <v-row><v-btn class="text-none text-body-1" @click="resetWebhookSecret()">Reset Webhook Secret</v-btn></v-row>
              <v-row class="text-h6 py-3" align='center'>
                Webhook URL <v-col class="pa-0" cols=6><v-text-field class="ml-3 mt-0 pt-0" hide-details v-model="groupData.webhook_url"/></v-col>
              </v-row>
              <v-row><v-btn class="text-none text-body-1" @click="resetWebhookURL(groupData.webhook_url)">Update Webhook URL</v-btn></v-row>
              <v-divider class="my-3"/>

              <v-row class="text-h5">Facebook Threatexchange</v-row>
              <v-row class="text-h6 py-3" align='center'>
                App ID <v-text-field class="ml-3 mt-0 pt-0" hide-details style="max-width: 424px;" v-model="threatexchangeID"/>
              </v-row>
              <v-row class="text-h6 py-3" align='center'>
                App Secret <v-text-field type="password" class="ml-3 mt-0 pt-0" hide-details style="max-width: 424px;" v-model="threatexchangeSecret"/>
              </v-row>
              <v-row><v-btn class="text-none text-body-1" @click="resetThreatexchangeKey()">Update ThreatExchange Credentials</v-btn></v-row>
              <v-divider class="my-3"/>

              <v-row class="text-h5">Virustotal</v-row>
              <v-row class="text-h6 py-3" align='center'>
                Virustotal Key <v-text-field type="password" class="ml-3 mt-0 pt-0" hide-details style="max-width: 424px;" v-model="groupData.virustotal_key"/>
              </v-row>
              <v-row><v-btn class="text-none text-body-1" @click="resetVirustotalKey(groupData.virustotal_key)">Update Virustotal Key</v-btn></v-row>
              <v-divider class="my-3"/>

              <v-row v-if="!notInGroup">
                <v-dialog width=325 v-model="showDeleteGroupDialog">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="text-none text-body-1 font-weight-bold white--text" color="red darken-3">Delete Group</v-btn>
                  </template>
                  <v-card class="px-6 py-3">
                    <v-row class="text-h5 mb-2 text-center" justify='center'>
                      Are you sure you want to delete the {{ groupData.name }} group?
                    </v-row>
                    <v-row justify='space-between'>
                      <v-btn class="text-none" @click="showDeleteGroupDialog = false">Cancel</v-btn>
                      <v-btn class="text-none text-body-1 font-weight-bold white--text" color="red darken-3" @click="deleteGroup(); showDeleteGroupDialog = false">Delete Group</v-btn>
                    </v-row>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { http } from '@/plugins/axios'
import AllowedExtensions from '@/components/groups/AllowedExtensions'
import DataRemoval from '@/components/groups/DataRemoval'
import EmbeddedDashboards from '@/components/groups/EmbeddedDashboards'
import ExtensionRequests from '@/components/groups/ExtensionRequests'
import GroupUsers from '@/components/groups/GroupUsers'
import Scrollspy from '@/components/Scrollspy.vue'
// import { getExtensionRequests } from '@/plugins/crxcavator_api'

export default {
  title: 'CRXcavator',
  components: {
    AllowedExtensions,
    DataRemoval,
    EmbeddedDashboards,
    ExtensionRequests,
    GroupUsers,
    Scrollspy
  },
  data () {
    return {
      allowedExtensionCount: null,
      avgRiskScore: null,
      webstoreIcon: require('@/assets/images/chrome_webstore_icon.png'),
      totalAllowedExtensions: 114,
      sortableStuff: ['Risk Score', '# Users', 'Recently Updated'],
      groupData: {},
      secretHidden: true,
      webhookSecretHidden: true,
      showDeleteGroupDialog: false,
      notInGroup: false,
      scrollspyElements: [
        { id: 'allowedExtensions', text: 'Allowed Extensions' },
        { id: 'extensionRequests', text: 'Extension Requests' },
        { id: 'userManagement', text: 'User Management' },
        { id: 'embeddedDashboards', text: 'Embedded Dashboards' },
        { id: 'dataRemoval', text: 'User Data Removal' },
        { id: 'groupSettings', text: 'Group Settings' }
      ],
      threatexchangeID: '',
      threatexchangeSecret: ''
    }
  },
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) this.$refs.scrollspy.setActiveElement(entries[0].target.id)
    },
    getGroupData () {
      let path = '/group'
      // let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get(path)
        .then(response => {
          this.groupData = response.data
          if (this.groupData.extensions === undefined) {
            this.groupData.extensions = []
          }
          this.threatexchangeID = (response.data.threatexchange_key || '').split('|')[0]
          this.threatexchangeSecret = (response.data.threatexchange_key || '').split('|')[1]
          console.log(response.data)
        })
        .catch(error => { this.notInGroup = true; console.log(error) })
    },
    resetGroupSecret () {
      let path = '/group/secret'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.post(path, null, { headers: headers })
        .then(response => {
          this.groupData.secret = response.data.secret
          this.$refs.messageToast.showMessage('Group secret reset successfully', 'success')
        })
        .then(error => { this.$refs.messageToast.showMessage('Group secret reset failed: ' + error.message) })
    },
    resetWebhookURL (webhookURL) {
      let path = '/group'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = { 'webhookurl': webhookURL }
      http.post(path, data, { headers: headers })
        .then(response => { this.$refs.messageToast.showMessage('Webhook URL reset successfully', 'success') })
        .then(error => { this.$refs.messageToast.showMessage('Webhook URL reset failed: ' + error.message) })
    },
    resetWebhookSecret () {
      let path = '/group/webhooksecret'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.post(path, null, { headers: headers })
        .then(response => {
          this.groupData.webhook_secret = response.data.webhook_secret
          this.$refs.messageToast.showMessage('Webhook secret reset successfully', 'success')
        })
        .then(error => { this.$refs.messageToast.showMessage('Webhook secret reset failed: ' + error.message) })
    },
    resetThreatexchangeKey () {
      let path = '/group/threatexchange'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = JSON.stringify({ 'threatexchangeKey': this.threatexchangeKey })
      http.post(path, data, { headers: headers })
        .then(response => { this.$refs.messageToast.showMessage('Threatexchange info set successfully', 'success') })
        .then(error => { this.$refs.messageToast.showMessage('Virustotal info set failed: ' + error.message) })
    },
    resetVirustotalKey (vtKey) {
      let path = '/group/virustotal'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = { 'virusTotalKey': vtKey }
      http.post(path, data, { headers: headers })
        .then(response => { this.$refs.messageToast.showMessage('Virustotal key reset successfully', 'success') })
        .then(error => { this.$refs.messageToast.showMessage('Virustotal key reset failed: ' + error.message) })
    },
    deleteGroup () {
      let path = '/group'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.delete(path, { headers: headers })
        .then(response => {
          this.$refs.messageToast.showMessage('Group has been deleted', 'success')
          setTimeout(() => { this.$router.push('/') }, 3000)
        }).catch(error => this.$refs.messageToast.showMessage('Error deleting group: ' + error.message))
    }
  },
  computed: {
    threatexchangeKey () {
      return this.threatexchangeID + '|' + this.threatexchangeSecret
    }
  },
  mounted () {
    this.getGroupData()
  }
}
</script>
