import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { http } from '@/plugins/axios'
import { Auth } from '@aws-amplify/auth'
import { page } from 'vue-analytics'
import AcceptGroupInvite from './views/AcceptGroupInvite.vue'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import EmbeddedDashboard from './views/EmbeddedDashboard.vue'
import EnterpriseDashboard from './views/EnterpriseDashboard.vue'
import ExtensionReport from './views/ExtensionReport.vue'
import Register from './views/Register.vue'
import UserSettings from './views/UserSettings.vue'
// import Docs from './views/Docs.vue'
const ViewSource = () => import('./views/ViewSource.vue')
const APIDocs = () => import('./views/APIDocs.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/report/:extension_id/:version',
      name: 'Extension Report',
      component: ExtensionReport
    },
    {
      path: '/report/:extension_id',
      name: 'Extension Report (latest)',
      component: ExtensionReport
    },
    {
      path: '/source/:extension_id/:version',
      name: 'Extension Source',
      component: ViewSource
    },
    {
      path: '/apidocs',
      name: 'API Documentation',
      component: APIDocs
    },
    {
      path: '/docs',
      name: 'CRXcavator Documentation',
      beforeEnter () { location.href = '/docs.html' }
    },
    {
      path: '/dashboard',
      name: 'Enterprise Dashboard',
      component: EnterpriseDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/group/accept/:token',
      name: 'Accept Group Invite',
      component: AcceptGroupInvite
    },
    {
      path: '/embed/:dashboard_id',
      name: 'Embedded Dashboard',
      component: EmbeddedDashboard
    },
    {
      path: '/settings',
      name: 'User Settings',
      component: UserSettings,
      meta: { requiresAuth: true }
    },
    {
      path: '/user/settings',
      redirect: '/settings'
    },
    {
      path: '*',
      component: Home
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  // Google Analytics
  page(to.path)

  if (store.state.authStatus === 'SIGNED_IN') {
    if (to.name === 'login') {
      // User is already logged in, redirect to home
      console.log('Point 1')
      next('/')
    } else {
      next()
      console.log('Point 2')
    }
    // next()
  } else {
    Auth.currentAuthenticatedUser()
      .then(user => {
        store.commit('storeCognitoUser', { user: user })
        store.commit('storeAuthStatus', { authStatus: 'SIGNED_IN' })
        if (to.name === 'login') {
          // User is already logged in, redirect to home
          console.log('Point 3')
          next('/')
        } else {
          console.log('Point 4')
          next()
        }
        http.get('/group', { headers: { 'cognito-token': user.signInUserSession.accessToken.jwtToken } })
          .then(response => { store.commit('storeGroupStatus', { inGroup: true }) })
          .catch(error => { console.log(error) })
        // next()
      }).catch(() => {
        // The user is not logged in
        console.log('Point 5')
        // Redirect user to login (USE THIS IF REQUIRING AUTH FOR FULL SITE)
        // to.name === 'login' ? next() : next('/login')

        // Redirect user to home if trying to get restricted page (USE THIS OTHERWISE)
        if (to.matched.some(record => record.meta.requiresAuth)) {
          next('/')
        } else {
          next()
        }
      })
  }
})

export { router as default }
