
<template>
  <v-navigation-drawer app temporary v-model="sidebarval">
    <v-list-item class="primary" dark>
      <v-list-item-content>
        <v-list-item-title class="title">Menu</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action><v-btn @click="sidebarval = !sidebarval;" icon text><v-icon>close</v-icon></v-btn></v-list-item-action>
    </v-list-item>
    <v-divider/>
    <v-list>
      <SidenavEntry v-for="(navitem, index) in navigationItems" :key="index" :title="index" :items="navitem"/>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import SidenavEntry from '@/components/SidenavEntry'

export default {
  components: {
    SidenavEntry
  },
  data () {
    return {
      sidebarval: this.sidebar
    }
  },
  props: {
    sidebar: Boolean,
    navigationItems: {
      type: Object,
      default: () => {
        return {
          'Home': ['/'],
          'Docs': ['/docs'],
          'API Docs': ['/apidocs'],
          'Dashboard': ['/dashboard']
        }
      }
    }
  },
  watch: {
    sidebar () {
      this.sidebarval = this.sidebar
    },
    sidebarval () {
      this.$emit('update:sidebar', this.sidebarval)
    }
  }
}
</script>
