<template>
  <v-tooltip top>
    <template v-slot:activator="{ on: tooltip }">
      <v-dialog v-model="showDialog" width="350">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="{ ...on, ...tooltip }">
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </template>
        <v-card class="pa-4">
          <v-row class="mx-0 text-h6 mb-2">Are you sure you want to remove this extension from your allowlist?</v-row>
          <v-row class="mx-0" justify='center'>
            <v-btn @click="blockExtension()" class="text-none text-body-1" dark color="red darken-2">Remove Extension</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
    <span>Remove Extension</span>
  </v-tooltip>
</template>
<script>
export default {
  data () {
    return {
      showDialog: false
    }
  },
  methods: {
    blockExtension () {
      this.$emit('blockextension')
      this.showDialog = false
    }
  }
}
</script>
