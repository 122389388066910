<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="Risk Over Time" :infoText="infoText"/>
    <VueApexCharts height="300" type="line" :options="options" :series="series"/>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    ReportSectionTopbar,
    VueApexCharts
  },
  props: {
    riskOverTime: Array
  },
  data () {
    return {
      infoText: 'This graph shows how this risk score has changed for this extension across multiple versions.',
      riskSections: [
        { 'val': 'csp', 'title': 'CSP' },
        { 'val': 'optional_permissions', 'title': 'Optional Permissions' },
        { 'val': 'permissions', 'title': 'Permissions' },
        { 'val': 'retire', 'title': 'RetireJS' },
        { 'val': 'webstore', 'title': 'Webstore' }
      ],
      series: [{
        name: 'Risk Score',
        data: this.riskOverTime
      }],
      options: {
        markers: {
          size: 1
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let entry = w.config.series[seriesIndex].data[dataPointIndex]
            let tooltip = '<div class="arrow_box text-body-1 py-2 px-5">'
            tooltip += '<div class="row">Version: ' + entry.version + '</div>'
            tooltip += '<div class="row">Date: ' + entry.x + '</div>'
            if (entry.y != 0) tooltip += `<div class="row">Risk: ${entry.y}&nbsp;`
            if (entry.changes.total && entry.changes.total != 0) {
              tooltip += entry.changes.total > 0 ? `(<span class="red--text text--darken-2">+` : `(<span class="green--text text--darken-1">`
              tooltip += `${entry.changes.total}</span>)`
            }
            tooltip += '</div>'
            for (var field of this.riskSections) {
              if (entry.changes[field.val] && entry.changes[field.val] != 0) {
                let changeAmount
                if (entry.changes[field.val] > 0) changeAmount = `<span class="red--text text--darken-2">+${String(entry.changes[field.val])}</span>`
                else changeAmount = `<span class="green--text text--darken-1">${String(entry.changes[field.val])}</span>`
                tooltip += `<div class="row">${field.title}&nbsp; ${changeAmount}</div>`
              }
            }
            tooltip += '</div>'
            return tooltip
          }.bind(this)
        },
        stroke: {
          curve: 'smooth'
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy-MM',
              month: 'yyyy-MM',
              day: 'dd MMM'
            }
          },
          tooltip: {
            formatter: function (val, opts) {
              return new Date(val).toISOString().split('T')[0]
            }
          }
        },
        yaxis: {
          min: 0
        }
      }
    }
  }
}
</script>
