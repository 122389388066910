<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="Allowed Related Extensions" :infoText="infoText"/>
    <v-expansion-panels v-model="panelExpanded" flat class="px-4 pb-4">
      <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="py-0">
          <v-col class="pa-0">
            <v-row>
              <v-col cols=3 class="py-0" v-for="(extension, index) in relatedExtensions.slice(0, 4)" :key="index">
                <RelatedExtensionCard v-bind="extension"/>
              </v-col>
            </v-row>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-row class="pt-3">
            <v-col cols=3 v-for="(extension, index) in relatedExtensions.slice(4)" :key="index">
              <RelatedExtensionCard v-bind="extension"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row class="mx-0" v-if="relatedExtensions.length > 4">
      <v-btn block text @click="expandPanel()" class="py-5">See {{ panelExpanded === 0 ? 'Less' : 'More'}}<v-icon>expand_{{ panelExpanded === 0 ? 'less' : 'more'}}</v-icon></v-btn>
    </v-row>
  </v-card>
</template>
<script>
import RelatedExtensionCard from '@/components/reports/RelatedExtensionCard'
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'

export default {
  components: {
    RelatedExtensionCard,
    ReportSectionTopbar
  },
  props: {
    relatedExtensions: Array,
    extensionPlatform: {
      type: String,
      default: 'Chrome'
    }
  },
  data () {
    return {
      infoText: 'This section lists related extensions from the ' + (this.$route.query.platform || 'Chrome') + ' Web Store\'s page for this extension that have already been allowed for your organization.',
      panelExpanded: undefined
    }
  },
  methods: {
    expandPanel () {
      if (this.panelExpanded === undefined) {
        this.panelExpanded = 0
        setTimeout(() => { this.$vuetify.goTo('#allowedRelatedExtensions') }, 100)
      } else {
        this.panelExpanded = undefined
      }
    }
  }
}
</script>
