<template>
  <v-app>
    <!-- <Sidenav :navigation-items="navigationStructure" :sidebar.sync="sidebar"/> -->
    <Sidenav :sidebar.sync="sidebar"/>
    <Topbar v-if="this.$route.name !== 'Embedded Dashboard'" :sidebar.sync="sidebar"/>
    <v-main class="background">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Sidenav from './components/Sidenav'
import Topbar from './components/Topbar'
import { Auth } from '@aws-amplify/auth'

export default {
  title: 'CRXcavator',
  name: 'App',
  components: {
    Sidenav,
    Topbar
  },
  beforeMount () {
    this.refreshToken()
    window.setInterval(() => {
      this.refreshToken()
    }, 600000)
  },
  data () {
    return {
      sidebar: false
    }
  },
  methods: {
    async refreshToken () {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        console.log(cognitoUser)
        const currentSession = await Auth.currentSession()
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          console.log('session', err, session)
          this.$store.commit('storeCognitoUser', { user: cognitoUser })
          this.$store.commit('storeAuthStatus', { authStatus: 'SIGNED_IN' })
          // const { idToken, refreshToken, accessToken } = session
          // do whatever you want to do now :)
        })
      } catch (e) {
        console.log('Unable to refresh Token', e)
      }
    }
  },
  mounted () {
    console.log(this)
  }
}

</script>
<style>
@font-face {
  font-family: "NHaasGrotesk";
  src: url("assets/fonts/NHaasGroteskDSPro-55Rg.otf");
}
@font-face {
  font-family: "NHaasGroteskThin";
  src: url("assets/fonts/NHaasGroteskDSPro-25Th.otf");
}
.NHaas {
  font-family: "NHaasGrotesk" !important;
}
.NHaasThin {
  font-family: "NHaasGroteskThin" !important;
}
/* .row {
  margin-left: 0;
  margin-right: 0;
}
.col {
  padding: 0;
} */
.v-application {
  padding: 0;
}
.v-application p {
  margin-bottom: 0 !important;
}
</style>
