<template>
  <v-container fluid>
    <MessageToast ref="messageToast"/>
    <v-row justify='center' class="pt-12">
      <v-col cols=6 lg=4>
        <v-card class="py-4 px-7" elevation=3>
          <v-row>
            <v-col class="text-h4 py-0 flex-nowrap">User Settings</v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="text-h6 py-0" v-if="userEmail.length < 1"><v-skeleton-loader type="text"/></v-col>
            <v-col class="text-h6 py-0" v-else>{{ userEmail }}</v-col>
          </v-row>
          <v-divider/>
          <v-row align='center' class="my-2">
            <v-col class="text-h5 text-center" cols=3>API Key</v-col>
            <v-col cols=9>
              <v-text-field class="pt-0 mt-0" :type="apiKeyHidden ? 'password' : 'text'" :value="apiKey" hide-details readonly append-icon="visibility" @click:append="apiKeyHidden = !apiKeyHidden"/>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col class="py-1">
              <v-btn class="text-none text-body-1" @click="resetApiKey()" :loading="resetApiKeyLoading">Reset API Key</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1">
              <v-dialog width=350>
                <template v-slot:activator="{ on }">
                  <v-btn class="text-none text-body-1" v-on="on">Change Password</v-btn>
                </template>
                <ChangePassword v-on:error="$refs.messageToast.showMessage($event)"/>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row align='center' v-if="userGroupName.length > 0">
            <v-col class="text-h6 haswhitespace flex-grow-0">Part of <strong>{{ userGroupName }}</strong></v-col>
            <v-col>
              <v-dialog width=325 v-model="showLeaveGroupDialog">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="text-none text-body-1 font-weight-bold white--text" color="red darken-3">Leave Group</v-btn>
                </template>
                <v-card class="px-6 py-3">
                  <v-row class="text-h5 mb-1" justify='center'>
                    Are you sure?
                  </v-row>
                  <v-row class="mb-2 text-center">
                    If you leave this group and need to use enterprise features again, an admin from your group will need to re-add your account.
                  </v-row>
                  <v-row justify='space-between'>
                    <v-btn class="text-none" @click="showLeaveGroupDialog = false">Cancel</v-btn>
                    <v-btn class="text-none text-body-1 font-weight-bold white--text" color="red darken-3" @click="leaveGroup(); showLeaveGroupDialog = false">Leave Group</v-btn>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row v-if="notInGroup">
            <v-col>
              <CreateGroup v-on:group-created="getGroupMembership()"/>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { http } from '@/plugins/axios'
import ChangePassword from '@/components/cognito/ChangePassword'
import CreateGroup from '@/components/groups/CreateGroup'

export default {
  title: 'CRXcavator',
  components: {
    ChangePassword,
    CreateGroup
  },
  data () {
    return {
      apiKey: '',
      apiKeyHidden: true,
      resetApiKeyLoading: false,
      showLeaveGroupDialog: false,
      userGroupName: '',
      notInGroup: false
    }
  },
  computed: {
    userEmail () {
      return this.$store.state.cognitoUser.signInUserSession.idToken.payload.email
    }
  },
  methods: {
    getApiKey () {
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get('/user/apikey', { headers: headers }).then(response => {
        this.apiKey = response.data.api_key
      })
    },
    resetApiKey () {
      this.resetApiKeyLoading = true
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.post('/user/apikey', null, { headers: headers }).then(response => {
        this.resetApiKeyLoading = false
        this.apiKey = response.data.api_key
      }).catch(error => console.log(error))
    },
    getGroupMembership () {
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get('/group', { headers: headers })
        .then(response => {
          this.userGroupName = response.data.name
          this.notInGroup = false
        })
        .catch(error => {
          console.log(error)
          this.notInGroup = true
        })
    },
    leaveGroup () {
      var headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.delete('/group/user/leave', { headers: headers }).then(response => {
        this.$refs.messageToast.showMessage('Successfully left group', 'success')
        setTimeout(() => { this.$router.push('/') }, 3000)
      }).catch(error => {
        this.$refs.messageToast.showMessage('Error leaving group: ' + error.message)
      })
    }
  },
  mounted () {
    this.getApiKey()
    this.getGroupMembership()
  }
}
</script>
