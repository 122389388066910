<template>
  <!-- <v-dialog max-width=400 v-model="authenticatorShown"> -->
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-none" v-bind="attrs" v-on="on">Login/Register</v-btn>
    </template> -->
  <SignInFlow v-if="authStatus === '' || authStatus === 'SIGN_IN' || authStatus === 'SIGNED_IN' || authStatus == undefined" v-on:signed-in="closeAuthenticator()"/>
  <SignUpFlow v-else-if="authStatus === 'SIGN_UP'"/>
  <ForgotPasswordFlow v-else-if="authStatus === 'FORGOT_PASSWORD'"/>
  <!-- </v-dialog> -->
</template>
<script>
import SignInFlow from '@/components/cognito/SignInFlow'
import SignUpFlow from '@/components/cognito/SignUpFlow'
import ForgotPasswordFlow from '@/components/cognito/ForgotPasswordFlow'

export default {
  name: 'Authenticator',
  components: {
    SignInFlow,
    SignUpFlow,
    ForgotPasswordFlow
  },
  data () {
    return {
      authenticatorShown: false
    }
  },
  methods: {
    closeAuthenticator () {
      setTimeout(() => {
        this.authenticatorShown = false
        this.$emit('close-window')
      }, 4000)
    }
  },
  computed: {
    authStatus () { return this.$store.state.authStatus }
  },
  watch: {
    authenticatorShown (val) {
      if (!val && this.authStatus !== 'SIGNED_IN') {
        // Resets auth window to sign in page when closed (If user is not logged in)
        this.$store.commit('storeAuthStatus', { authStatus: 'SIGN_IN' })
      }
    }
  }
}
</script>
<style>
.v-stepper__wrapper {
  overflow: visible !important;
}
</style>
