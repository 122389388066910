<template>
  <v-dialog v-model="showDialog" max-width=400>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" :disabled="user.username === $store.state.cognitoUser.attributes.email"><v-icon>build</v-icon></v-btn>
    </template>
    <v-card class="px-4 py-2">
      <v-col>
        <v-row class="text-h6 mb-2">Manage User: {{ user.username }}</v-row>
        <v-row>
          <v-switch v-model="userAdminStatus" label="Super Admin"/>
        </v-row>
        <v-row class="text-h6">Permissions</v-row>
        <v-row class="mb-4">
          <v-col class="grey lighten-4 rounded elevation-1" style="max-height: 300px; overflow: scroll;">
            <v-row v-for="(permission, index) in availablePermissions" :key="index" class="mx-0">
              <v-checkbox dense hide-details :disabled="userAdminStatus" :label="permission" :value="permission" v-model="userPermissions"/>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-btn class="text-body-1 text-none" dark color="red darken-2" @click="removeUser(user.username)" :loading="userRemoveLoading">Remove User</v-btn>
          <v-btn class="text-body-1 text-none" @click="updateUser()" :disabled="userAdminStatus == initialUserAdminStatus && !permissionsChanged" :loading="permissionSubmitLoading || adminSubmitLoading">Submit Changes</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  props: {
    user: Object
  },
  data () {
    return {
      userAdminStatus: '',
      initialUserAdminStatus: '',
      availablePermissions: [],
      userPermissions: null,
      permissionsChanged: false,
      showDialog: false,
      permissionSubmitLoading: false,
      adminSubmitLoading: false,
      userRemoveLoading: false
    }
  },
  methods: {
    getUser (username) {
      let path = '/group/permissions/' + username
      http.get(path)
        .then(response => {
          this.userAdminStatus = response.data.super_admin
          this.initialUserAdminStatus = response.data.super_admin
          this.availablePermissions = response.data.available_permissions
          this.userPermissions = response.data.permissions
        }).catch(error => {
          this.$emit('message', ['Error getting user data: ' + error.response.data.error])
          this.showDialog = false
        })
    },
    updatePermissions (username) {
      this.permissionSubmitLoading = true
      console.log('Updating permissions')
      let path = '/group/permissions/' + username
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = JSON.stringify({ 'permissions': this.userPermissions })
      http.post(path, data, { headers: headers })
        .then(response => {
          this.$emit('message', ['Permissions changed successfully', 'success'])
        }).catch(error => {
          this.$emit('message', ['Permissions change failed: ' + error.response.data.error])
        }).then(() => {
          this.showDialog = false
          this.permissionSubmitLoading = false
        })
    },
    makeAdmin (username) {
      this.adminSubmitLoading = true
      console.log('Adding admin status')
      let path = '/group/superadmin/' + username
      http.post(path, null)
        .then(response => {
          this.$emit('message', ['Admin status changed', 'success'])
          this.$emit('adminstatuschange', true)
        }).catch(error => {
          this.$emit('message', ['Admin status change failed: ' + error.response.data.error])
        }).then(() => {
          this.showDialog = false
          this.adminSubmitLoading = false
        })
    },
    removeAdmin (username) {
      this.adminSubmitLoading = true
      console.log('Removing admin status')
      let path = '/group/superadmin/' + username
      http.delete(path)
        .then(response => {
          this.$emit('message', ['Admin status changed', 'success'])
          this.$emit('adminstatuschange', false)
        }).catch(error => {
          this.$emit('message', ['Admin status change failed: ' + error.response.data.error])
        }).then(() => {
          this.showDialog = false
          this.adminSubmitLoading = false
        })
    },
    updateUser () {
      if (this.initialUserAdminStatus != this.userAdminStatus) {
        if (this.userAdminStatus) this.makeAdmin(this.user.username)
        else this.removeAdmin(this.user.username)
      }
      if (this.permissionsChanged) this.updatePermissions(this.user.username)
    },
    removeUser (username) {
      this.userRemoveLoading = true
      let path = '/group/user/remove/' + username
      http.delete(path)
        .then(response => {
          this.showDialog = false
          console.log(response)
        }).catch(error => {
          this.$emit('message', ['Remove user failed: ' + error.response.data.error])
        }).then(() => {
          this.showDialog = false
          this.userRemoveLoading = false
        })
    }
  },
  watch: {
    userPermissions (_, prev) {
      console.log('Change detected: ' + prev)
      if (prev) this.permissionsChanged = true
    },
    showDialog (val) {
      if (val) this.getUser(this.user.username)
    }
  }
}
</script>
