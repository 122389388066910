<template>
  <v-stepper class="pa-3" v-model="forgotPassStep">
    <MessageToast ref="messageToast"/>
    <v-stepper-items>
      <v-stepper-content step=1>
        <v-col class="py-0">
          <v-row class="text-h4 mb-1">Password Reset</v-row>
          <v-row class="mb-1">Enter the email address you use to login</v-row>
          <v-row>
            <v-text-field label="Email Address" v-model="userEmail"/>
          </v-row>
          <v-row justify='end'>
            <v-btn class="text-none" :disabled="userEmail.length < 1" @click='forgotPassword()' :loading="forgotPasswordLoading">Submit</v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=2>
        <v-col class="py-0">
          <v-row class="text-h4 mb-1">Password Reset Confirmation</v-row>
          <v-row>
            <v-text-field prepend-icon="person" label="Email Address" v-model="userEmail"/>
          </v-row>
          <v-row>
            <v-text-field v-mask="'######'" prepend-icon="mdi-lock-check" label="Password Reset Code" v-model="code"/>
          </v-row>
          Passwords must be
          <ul>
            <li>Be at least 16 characters long</li>
            <li>Contain at least 1 uppercase letter</li>
            <li>Contain at least 1 lowercase letter</li>
            <li>Contain at least 1 special character</li>
          </ul>
          <v-row>
            <v-text-field
              label="Enter Your New Password"
              prepend-icon="vpn_key"
              :rules="passwordRules"
              type="password"
              v-model="newUserPassword"
              validate-on-blur/>
          </v-row>
          <v-row>
            <v-text-field
              label="Re-enter Your New Password"
              prepend-icon="vpn_key"
              :rules="passwordMatchRule"
              type="password"
              v-model="newUserPasswordConfirm"
              validate-on-blur/>
          </v-row>
          <v-row justify='end'>
            <v-btn
              class="text-none text-body-1 font-weight-bold white--text"
              @click='forgotPasswordSubmit()'
              color="primary"
              :disabled="userEmail.length < 1 || newUserPassword.length < 1 || newUserPasswordConfirm.length < 1 || newUserPassword != newUserPasswordConfirm"
              :loading="passwordResetLoading"
              large>
              Change Password
            </v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=3>
        <v-row class="px-0 py-3 text-h5" align='center' justify='center'>Password reset successful <v-icon large color="primary" class="ml-2">check</v-icon></v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { Auth } from '@aws-amplify/auth'
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask
  },
  data () {
    return {
      forgotPassStep: 1,
      forgotPasswordLoading: false,
      passwordResetLoading: false,
      userEmail: '',
      user: null,
      code: '',
      newUserPasswordConfirm: '',
      newUserPassword: '',
      passwordRules: [
        () => this.userPassword.length >= 16 || 'Password must be more than 16 characters',
        () => /[a-z]/.test(this.userPassword) || 'Password must contain a lowercase letter',
        () => /[A-Z]/.test(this.userPassword) || 'Password must contain an uppercase letter',
        () => /[=+-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(this.userPassword) || 'Password must contain a special character'
      ],
      passwordMatchRule: [
        () => this.userPassword === this.userPasswordConfirm || 'Passwords must match'
      ]
    }
  },
  methods: {
    async forgotPassword () {
      this.forgotPasswordLoading = true
      Auth.forgotPassword(this.userEmail)
        .then(data => {
          console.log(data)
          this.forgotPassStep = 2
          this.forgotPasswordLoading = false
        })
        .catch(err => {
          this.forgotPasswordLoading = false
          this.$refs.messageToast.showMessage(err.message)
        })
    },
    async forgotPasswordSubmit () {
      this.passwordResetLoading = true
      Auth.forgotPasswordSubmit(this.userEmail, this.code, this.newUserPassword)
        .then(data => {
          console.log(data)
          this.forgotPassStep = 3
          setTimeout(() => this.$store.commit('storeAuthStatus', { authStatus: 'SIGN_IN' }), 3000)
        })
        .catch(err => {
          this.$refs.messageToast.showMessage(err.message)
        })
        .then(() => { this.passwordResetLoading = false })
    }
  }
}
</script>
