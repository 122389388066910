<template>
  <v-card class="px-8 py-5">
    <v-row class="text-h5">User Data Removal</v-row>
    <v-row>
      <v-text-field v-model="user" style="max-width: 400px" placeholder="User ID to be deleted" />
    </v-row>
    <v-row>
      <v-btn @click="deleteUserData()" class="text-none text-body-1" :disabled="user.length === 0" :loading="loading">Delete User Data</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  data () {
    return {
      user: '',
      loading: false
    }
  },
  methods: {
    deleteUserData () {
      this.loading = true
      let path = '/group/users/extensions/' + this.user
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.delete(path, headers)
        .then(response => {
          console.log(response)
          this.$emit('message', ['User data deleted successfully', 'success'])
        })
        .catch(error => {
          console.dir(error)
          this.$emit('message', ['Could not delete user data: ' + error.message])
        })
        .then(() => {
          this.loading = false
          this.user = ''
        })
    }
  }
}
</script>
