<template>
  <div class="pl-3 pr-2" style="background-color: rgba(0, 0, 0, 0.06); border-radius: 28px">
    <v-autocomplete
      class="py-0"
      v-model="extensionSelection"
      :items="searchResults"
      :label="label"
      :search-input.sync="searchQuery"
      single-line
      hide-details
      :hide-no-data="!searchQuery || searchQuery.length < 3"
      item-text="name"
      return-object
    >
      <template v-slot:append>
        <v-icon>search</v-icon>
      </template>
      <template v-slot:item="data">
        <v-row class="my-1" align='center'>
          <img class="mx-3" height="48px" width="48px" :src="data.item.icon"/>
          <v-col class="py-1 searchbar-item-text">
            <v-row class="text-h6">{{ data.item.name }}</v-row>
            <v-row>{{ data.item.extension_id }}</v-row>
            <v-row>{{ data.item.platform }}</v-row>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  name: 'SearchBar',
  props: {
    label: {
      type: String,
      default: 'Find an extension'
    }
  },
  data () {
    return {
      loading: false,
      searchQuery: '',
      searchResults: [],
      extensionSelection: null
    }
  },
  methods: {
    goToExtension (extensionID, platform = 'Chrome', newScan = false) {
      var path = '/report/' + extensionID + '?platform=' + platform
      if (newScan) path += '&new_scan=true'
      this.$router.push(path)
    },
    submitQuery () {
      // Submits search to API when more the query changes
      var path = '/search?q=' + this.searchQuery
      http.get(path)
        .then(response => {
          this.searchResults = response.data
        })
        .catch(error => { console.log(error) })
    }
  },
  watch: {
    browserType () {
      this.searchResults = []
    },
    searchQuery (val) {
      if (!val || val.length < 3) return
      this.submitQuery()
    },
    extensionSelection (selection) {
      // Routes the client to the report page when an extension is selected from the search
      this.goToExtension(selection.extension_id, selection.platform)
    }
  }
}
</script>
<style scoped>
.searchbar-item-text >>> .row {
  line-height: 1.25rem !important;
}
.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
.v-text-field >>> .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.v-select__selections >>> input {
  display: none;
}
</style>
