<template>
  <v-row style="border-radius: 28px;">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="primary" size="96"/>
    </v-overlay>
    <v-col class="py-0 flex-grow-0 pr-2" :style="'border-radius: 28px 0px 0px 28px; background-color: ' + browserType.color">
      <v-select
        append-icon=""
        class="mt-0 pt-0 text-h6 align-center"
        hide-details
        :items="browsers"
        :menu-props="{ 'nudge-left': '14', 'offset-y': true }"
        return-object
        style="height: 100%;"
        v-model="browserType">
        <template v-slot:item="{ item }">
          <v-row class="mx-0" align='center'>
            <v-icon class="pr-1">{{ item.icon }}</v-icon>{{ item.title }}
          </v-row>
        </template>
        <template v-slot:selection="{ item }">
          <v-row class="mx-0 flex-nowrap font-weight-bold white--text" style="width: initial;" align='center' justify='center' id="searchbar-selection-row">
            <v-col class="pa-0"><v-icon color="white" class="pr-1">{{ item.icon }}</v-icon></v-col><v-col class="pa-0">{{ item.title }}</v-col><v-icon color="white">expand_more</v-icon>
          </v-row>
        </template>
        <template v-slot:prepend-item>
          <v-col class="pt-1 px-2">Select Browser Type</v-col>
        </template>
      </v-select>
    </v-col>
    <v-col class="pa-0">
      <v-autocomplete
        class="pt-0"
        v-model="extensionSelection"
        :items="searchResults"
        :label="label"
        :search-input.sync="searchQuery"
        filled
        single-line
        style="border-radius: 0px 28px 28px 0px;"
        hide-details
        no-data-text="No results"
        :hide-no-data="!searchQuery || searchQuery.length < 3 || searchbarLoading"
        :loading="searchbarLoading"
        item-text="name"
        return-object
      >
        <template v-slot:append>
          <v-icon>search</v-icon>
        </template>
        <template v-slot:item="data">
          <v-row class="my-1" align='center'>
            <img class="mx-3" height="48px" width="48px" :src="data.item.icon"/>
            <v-col class="py-1 searchbar-item-text">
              <v-row class="text-h6">{{ data.item.name }}</v-row>
              <v-row>{{ data.item.extension_id }}</v-row>
              <v-row>{{ data.item.platform }}</v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:prepend-item v-if="searchQueryValidID">
          <v-list-item v-if="browserType.value === ''" class="text-h6">
            Submit Extension ID <v-spacer/>
            <v-radio-group row v-model="platformSelection">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-radio v-on="on" value='Chrome' off-icon="mdi-google-chrome" on-icon="mdi-google-chrome"/>
                </template>
                <span>Submit as Chrome Extension</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-radio v-on="on" value='Firefox' off-icon="mdi-firefox" on-icon="mdi-firefox"/>
                </template>
                <span>Submit as Firefox Extension</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-radio v-on="on" value='Edge' off-icon="mdi-microsoft-edge" on-icon="mdi-microsoft-edge"/>
                </template>
                <span>Submit as Edge Extension</span>
              </v-tooltip>
            </v-radio-group>
            <v-divider class="mr-3" vertical/>
            <v-btn @click="scanExtension(searchQuery, platformSelection)" icon><v-icon>input</v-icon></v-btn>
          </v-list-item>
          <v-list-item v-else @click='scanExtension(searchQuery, browserType.value)' class="text-h6">Submit extension ID for scanning <v-spacer/><v-icon>input</v-icon> </v-list-item>
          <v-divider/>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  name: 'SearchBar',
  props: {
    label: {
      type: String,
      default: 'Find or Submit an extension'
    }
  },
  data () {
    return {
      browsers: [
        { icon: 'mdi-google-chrome', title: 'Chrome', value: 'Chrome', color: '#6BBF4E' },
        { icon: 'mdi-firefox', title: 'Firefox', value: 'Firefox', color: '#e85d0f' },
        { icon: 'mdi-microsoft-edge', title: 'Edge', value: 'Edge', color: '#0681d1' },
        { icon: 'web', title: 'All', value: '', color: 'grey' }
      ],
      browserType: { icon: 'web', title: 'All', value: '', color: 'grey' },
      loading: false,
      platformSelection: 'Chrome',
      searchbarLoading: false,
      searchQuery: '',
      searchResults: [],
      extensionSelection: null
    }
  },
  methods: {
    goToExtension (extensionID, platform = 'Chrome', newScan = false) {
      var path = '/report/' + extensionID + '?platform=' + platform
      if (newScan) path += '&new_scan=true'
      this.$router.push(path)
    },
    validateExtensionID (extensionID) {
      return /^[a-z]{32}$/.test(extensionID) || this.browserType.value === 'Firefox' || this.browserType.value === ''
    },
    scanExtension (extensionID, platform = 'Chrome') {
      this.loading = true
      if (platform === '') platform = 'Chrome'
      let path = '/submit?platform=' + platform
      let data = { 'extension_id': extensionID }
      http.post(path, data)
        .then(response => {
          if (response.data.error) {
            this.$emit('message', ['Could not find extension in webstore'])
            this.searchQuery = ''
          } else {
            this.goToExtension(extensionID, platform, true)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },
    submitQuery () {
      // Submits search to API when more the query changes
      this.searchbarLoading = true
      var path = '/search?q=' + this.searchQuery
      this.browserType.value !== '' ? path += '&platform=' + this.browserType.value : path += ''
      http.get(path)
        .then(response => {
          this.searchResults = response.data
        })
        .catch(error => { console.log(error) })
        .then(() => { this.searchbarLoading = false })
    }
  },
  computed: {
    searchQueryValidID () { return this.validateExtensionID(this.searchQuery) }
  },
  watch: {
    browserType () {
      this.searchResults = []
    },
    searchQuery (val) {
      if (!val || val.length < 3) return
      this.submitQuery()
    },
    extensionSelection (selection) {
      // Routes the client to the report page when an extension is selected from the search
      // this.goToExtension(selection.extension_id, selection.platform)
      this.scanExtension(selection.extension_id, selection.platform)
    }
  }
}
</script>
<style scoped>
.searchbar-item-text >>> .row {
  line-height: 1.25rem !important;
}
.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
.v-text-field >>> .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.v-select__selections >>> input {
  display: none;
}
</style>
