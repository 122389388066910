<template>
  <p>{{message}}</p>
</template>

<script>
import { http } from '@/plugins/axios'

export default {
  title: 'CRXcavator',
  name: 'AcceptGroupInvite',
  data () {
    return {
      message: 'Accepting invite...'
    }
  },
  mounted () {
    http.post('/group/invite/accept', { token: this.$route.params.token })
      .then(response => {
        if (response.error) {
          if (response.error === 'User does not exist. Please create a user before accepting this invite.') {
            this.message = response.error
          } else {
            this.message = 'An unknown error occurred'
          }
        } else {
          this.message = 'Invite accepted! Returning to home page in 5 seconds...'
          setTimeout(() => { this.$router.push({ path: '/' }) }, 5000)
        }
      })
      .catch(e => {
        console.log(e)
        this.message = 'An unknown error occurred'
      })
  }

}
</script>
