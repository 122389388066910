<template>
  <v-card class="px-8 py-5">
    <template v-if="authState === 'changePassword'">
      <v-row class="text-h5 mb-2">Change Password</v-row>
      <v-row>
        <v-text-field
          label="Enter Your Current Password"
          type="password"
          v-model="currentPassword"/>
      </v-row>
      <v-row class="mb-3">
        Passwords must be
        <ul>
          <li>Be at least 16 characters long</li>
          <li>Contain at least 1 uppercase letter</li>
          <li>Contain at least 1 lowercase letter</li>
          <li>Contain at least 1 special character</li>
        </ul>
      </v-row>
      <v-row>
        <v-text-field
          label="Enter Your New Password"
          :rules="passwordRules"
          type="password"
          v-model="newUserPassword"
          validate-on-blur/>
      </v-row>
      <v-row>
        <v-text-field
          label="Re-enter Your New Password"
          :rules="passwordMatchRule"
          type="password"
          v-model="newUserPasswordConfirm"
          validate-on-blur/>
      </v-row>
      <v-row justify='end'>
        <v-btn
          class="text-none text-body-1 font-weight-bold white--text"
          @click='changePassword()'
          color="primary"
          :disabled="currentPassword.length < 1 || newUserPassword.length < 1 || newUserPasswordConfirm.length < 1 || newUserPassword != newUserPasswordConfirm"
          :loading="passwordResetLoading"
          large>
          Change Password
        </v-btn>
      </v-row>
    </template>
    <v-row v-else class="px-0 py-3 text-h5" align='center' justify='center'>Password reset successful <v-icon large color="primary" class="ml-2">check</v-icon></v-row>
  </v-card>
</template>

<script>
import { Auth } from '@aws-amplify/auth'

export default {
  name: 'ChangePassword',
  data () {
    return {
      currentPassword: '',
      newUserPasswordConfirm: '',
      newUserPassword: '',
      passwordResetLoading: false,
      authState: 'changePassword',
      passwordRules: [
        () => this.newUserPassword.length >= 16 || 'Password must be more than 16 characters',
        () => /[a-z]/.test(this.newUserPassword) || 'Password must contain a lowercase letter',
        () => /[A-Z]/.test(this.newUserPassword) || 'Password must contain an uppercase letter',
        () => /[=+-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(this.newUserPassword) || 'Password must contain a special character'
      ],
      passwordMatchRule: [
        () => this.newUserPassword === this.newUserPasswordConfirm || 'Passwords must match'
      ]
    }
  },
  methods: {
    async changePassword () {
      this.passwordResetLoading = true
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, this.currentPassword, this.newUserPassword).then(data => {
            this.authState = 'passwordChanged'
            setTimeout(() => {
              this.authState = 'changePassword'
            }, 5000)
            console.log(data)
          }).catch(err => {
            this.$emit('error', 'Password Reset Error: ' + err.message)
            console.log(err)
          }).then(() => {
            this.passwordResetLoading = false
            this.currentPassword = ''
            this.newUserPasswordConfirm = ''
            this.newUserPassword = ''
          })
        })
        .then(data => console.log(data))
        .catch(err => console.log(err))
    }
  }
}
</script>
