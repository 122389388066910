<template>
  <v-row justify='center' class="mt-12 pt-12">
    <v-card width=400>
      <Authenticator v-on:close-window="$router.push('/')"/>
    </v-card>
  </v-row>
</template>

<script>
// import { AmplifyEventBus } from 'aws-amplify-vue'
// import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import Authenticator from '@/components/cognito/Authenticator'

export default {
  title: 'CRXcavator',
  name: 'register',
  components: {
    Authenticator
  },
  // data () {
  //   return {
  //     showAuthenticator: false
  //   }
  // },
  mounted () {
    this.$store.commit('storeAuthStatus', { authStatus: 'SIGN_UP' })
    // this.showAuthenticator = true
  }
}
</script>
<style>
  body {
    overflow: hidden;
  }
  /* #footer {
    font-family: NHaasGroteskThin !important;
  } */
</style>
