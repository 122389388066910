<template>
  <v-list-group v-if="items.length > 1">
    <template v-slot:activator>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </template>
    <v-list-item class="justify-center" v-for="(item, index) in items" :key="index" :to=item.link>{{ item.title }}</v-list-item>
    <v-divider/>
  </v-list-group>
  <v-list-item text class="text-none mx-0" style="height: 100%;" :to="items[0]" v-else>
    <v-list-item-title>{{ title }}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  props: {
    title: String,
    items: Array
  }
}
</script>
