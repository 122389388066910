<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="RetireJS Vulnerability Scan Results" :infoText="infoText"/>
    <v-row class="px-8" v-if="retireResults && retireResults.length > 0">
      <v-col cols=4 v-for="(result, index) in formattedResults" :key="index">
        <RetireJsScanCard :retireResult="result"/>
      </v-col>
    </v-row>
    <v-row v-else class="text-h6 pt-4 pb-8" justify='center'>
      The RetireJS vulnerability scan did not return any results for this extension
    </v-row>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'
import RetireJsScanCard from '@/components/reports/RetireJsScanCard'

export default {
  components: {
    ReportSectionTopbar,
    RetireJsScanCard
  },
  props: {
    retireResults: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      infoText: ''
    }
  },
  computed: {
    formattedResults () {
      var formattedResults = []
      this.retireResults.forEach(file => {
        file.results.filter(result => result.vulnerabilities !== undefined).forEach(result => {
          result.vulnerabilities.forEach(vuln => {
            formattedResults.push({
              'file': file.file.slice(file.file.lastIndexOf('/') + 1),
              'full_path': file.file.slice(file.file.slice(5).indexOf('/') + 6),
              'component': result.component,
              'detection': result.detection,
              'version': result.version,
              'identifiers': vuln.identifiers,
              'info': vuln.info,
              'severity': vuln.severity
            })
          })
        })
      })
      return formattedResults
    }
  }
}
</script>
