import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authStatus: 'SIGN_IN',
    inGroup: false
  },
  mutations: {
    storeCognitoUser (state, payload) {
      state.cognitoUser = payload.user
    },
    storeAuthStatus (state, payload) {
      state.authStatus = payload.authStatus
    },
    storeGroupStatus (state, payload) {
      state.inGroup = payload.inGroup
    },
    signOut (state) {
      console.log('Signing out in store')
      state.authStatus = 'SIGN_IN'
      delete state.cognitoUser
    }
  },
  actions: {
    signOut ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('signOut')
        resolve()
      })
    }
  },
  modules: {
  }
})
