<template>
  <v-data-table
    class="elevation-2"
    no-data-text="No embedded dashboards found for your group"
    :items="embeddedDashboards"
    :headers="headers"
    :loading="loading">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">Embedded Dashboards</v-toolbar-title>
        <v-spacer />
        <v-btn :loading="addDashboardLoading" @click="createDashboard()" class="text-body-1 text-none">Add New Dashboard</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:[`item.iframe_url`]="{ item }">
      {{ embeddedDashboardBaseURL + item.dashboard_id }}
      <v-tooltip top transition="fade-transition" color="rgba(0, 0, 0, 0.8)">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="addLinktoClipboard(embeddedDashboardBaseURL + item.dashboard_id, item)" v-on="on"><v-icon>link</v-icon></v-btn>
        </template>
        <span>{{ linkTooltipText }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
      <v-btn icon @click="deleteDashboard(item)"><v-icon>delete</v-icon></v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  data () {
    return {
      headers: [
        { text: 'Dashboard ID', value: 'dashboard_id' },
        { text: 'Type', value: 'type' },
        { text: 'IFrame URL', value: 'iframe_url' },
        { text: 'Delete', value: 'delete', align: 'end' }
      ],
      embeddedDashboards: [],
      embeddedDashboardBaseURL: '/embed/',
      loading: true,
      addDashboardLoading: false,
      linkTooltip: false,
      linkTooltipText: 'Copy URL'
    }
  },
  methods: {
    getEmbeddedDashboards () {
      let path = '/embed/dashboards'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get(path, { headers: headers })
        .then(response => {
          this.embeddedDashboards = response.data
        })
        .catch(error => { console.log(error) })
        .then(() => {
          this.loading = false
        })
    },
    createDashboard () {
      this.addDashboardLoading = true
      let path = '/embed/dashboard'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.post(path, null, { headers: headers })
        .then(response => {
          this.$emit('message', ['Dashboard created successfully', 'success'])
          this.embeddedDashboards.push({ 'dashboard_id': response.data.dashboard_id })
        })
        .catch(error => {
          this.$emit('message', ['Dashboard created failed: ' + error.message])
          console.log(error)
        })
        .then(() => { this.addDashboardLoading = false })
    },
    deleteDashboard (dashboard) {
      let path = '/embed/dashboard/' + dashboard.dashboard_id
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.delete(path, { headers: headers })
        .then(response => {
          console.log(response)
          this.$emit('message', ['Dashboard deleted successfully', 'success'])
          let index = this.embeddedDashboards.indexOf(dashboard)
          this.embeddedDashboards.splice(index, 1)
        })
        .catch(error => {
          this.$emit('message', ['Dashboard delete failed: ' + error.message])
          console.log(error)
        })
    },
    addLinktoClipboard (link, dashboard) {
      console.log(this.embeddedDashboards.indexOf(dashboard))
      var textArea = document.createElement('textarea')
      // textArea.style.display = 'none'
      textArea.value = link

      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)

      console.log(link)
      this.linkTooltipText = 'URL copied to clipboard!'
      this.linkTooltip = true
      setTimeout(function () {
        this.linkTooltip = false
        this.linkTooltipText = 'Copy URL'
      }.bind(this), 2000)
    }
  },
  mounted () {
    this.embeddedDashboardBaseURL = window.location.origin + '/embed/'
    this.getEmbeddedDashboards()
  }
}
</script>
