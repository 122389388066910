import { http } from '@/plugins/axios'

export default function scanCSP (csp, apikey) {
  return parseCSP(csp, apikey)
}

function parseCSP (csp, apikey) {
  var urls = []
  for (var key in csp) {
    csp[key].map(url => {
      urls.push(url)
    })
  }
  return cleanURLs(urls, apikey)
}

function cleanURLs (urls, apikey) {
  var cleanURLMap = {}
  var myRe = /(https|http|ws|wss):\/\/\*?\.?(\S*)/
  urls.map(url => {
    var results = myRe.exec(url)
    if (results) {
      if (results[1] != 'https' && results[1] != 'http') {
        cleanURLMap[url] = 'https://' + results[2]
      } else {
        cleanURLMap[url] = '' + results[1] + '://' + results[2]
      }
    } else {
      cleanURLMap[url] = null
    }
  })
  return submitToVirusTotal(cleanURLMap, apikey)
}

function submitToVirusTotal (cleanURLMap, apikey) {
  var toSubmit = []
  for (var key in cleanURLMap) {
    if (cleanURLMap[key] != null && !toSubmit.includes(cleanURLMap[key])) {
      toSubmit.push(cleanURLMap[key])
    }
  }
  var toSubmitGroups = []
  var temp = []
  for (var i = 0; i < toSubmit.length; i++) {
    if (!(i % 4) && i != 0) {
      toSubmitGroups.push(temp)
      temp = []
    }
    temp.push(toSubmit[i])
  }
  toSubmitGroups.push(temp)
  console.log(toSubmitGroups)
  for (var j = 0; j < toSubmitGroups.length; j++) {
    batchSubmit(toSubmitGroups[j], apikey)
  }
  return 'Done'
}

function batchSubmit (urls, apikey) {
  var data = {
    apiKey: apikey,
    urls: urls
  }
  console.log(JSON.stringify(data))
  http.post('/virustotal/report', data)
    .then(results => {
      console.log(results)
      return results.json()
    }).then(responseData => {
      console.log(responseData)
    })
}
