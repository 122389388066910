<template>
  <v-content style="height: 100%;">
    <MessageToast ref="messageToast"/>
    <v-img contain height="128px" :src="logo"/>
    <v-row justify="center" class="display-1">CRXcavator</v-row>
    <v-row justify="center" class="subtitle-1">Search for an extension or submit an extension ID to scan</v-row>
    <v-row justify="center" class="headline pt-2">We apologize for the extended outage of CRXcavator. We are working on getting it restored.</v-row>
    <v-row>
      <v-col class="pa-3" lg=4 md=6 cols=10 offset-lg=4 offset-md=3 offset=1>
        <SearchBar v-on:message="$refs.messageToast.showMessage(...$event)"/>
      </v-col>
    </v-row>
    <v-row justify='center'><v-btn href="/docs.html#/terms_of_use" target="_blank" height="24px" text class="text-none align-center anchor--text">Read Our Terms of Use<v-icon>open_in_new</v-icon></v-btn></v-row>
    <!-- <RecentScans/> -->
    <v-row class="mb-6">
      <v-col lg=4 md=6 offset-lg=4 offset-md=3 class="d-none d-md-block">
        <PopularScans/>
      </v-col>
    </v-row>
    <v-footer absolute dark color="rgba(107,191,78,0.9)" height="36" class="py-0 px-5" id="footer">
      <v-row align="center" class="headline">
        <div class="mr-2">Made with love by</div>
        <img height="20" contain :src="duo_logo"/>
        <div class="ml-2">Security</div>
        <v-spacer/>
        <img height="32" contain :src="duo_cisco_msg"/>
      </v-row>
    </v-footer>
  </v-content>
</template>

<script>
// import RecentScans from '@/components/RecentScans'
import PopularScans from '@/components/PopularScans'
import SearchBar from '@/components/SearchBar'

export default {
  title: 'CRXcavator',
  name: 'home',
  components: {
    // RecentScans,
    PopularScans,
    SearchBar
  },
  data () {
    return {
      logo: require('@/assets/images/crxcavator_beta_logo.png'),
      duo_logo: require('@/assets/images/duo-logo-white.png'),
      duo_cisco_msg: require('@/assets/images/duo_cisco_msg.png')
    }
  }
}
</script>
<style>
body {
  overflow: hidden;
}
.anchor--text {
  color: var(--v-anchor-base) !important;
}
/* #footer {
  font-family: NHaasGroteskThin !important;
} */
</style>
