<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="Content Security Policy" :infoText="infoText"/>
    <v-row class="pb-2">
      <v-col class="py-0" cols=3>
        <v-tabs color="black" vertical v-model="activeTab">
          <v-tab v-for="(entry, index) in Object.keys(csp)" :key="index">{{ entry }}</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="py-0" cols=9>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td>Location</td><td>VirusTotal</td><td>ThreatExchange</td><td style="text-align: center">SSL Labs</td>
              </tr>
            </thead>
            <transition-group name="list-fade" tag="tbody">
              <tr v-for="(item, index) in items.values" :key="items.title + index">
                <td>{{ item }}</td>
                <td><span v-html="outputVT(item)"></span></td>
                <td>No data yet</td>
                <td style="text-align: center"><v-btn :href="'https://www.ssllabs.com/ssltest/analyze.html?d=' + item" target="_blank" class="text-none">View Report</v-btn></td>
              </tr>
            </transition-group>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'
import scanCSP from '@/utility/VirusTotalScanner'
import { http } from '@/plugins/axios'

export default {
  components: {
    ReportSectionTopbar
  },
  props: {
    csp: Object
  },
  data () {
    return {
      infoText: 'This section outlines the Content Security Policy for this extension. Each section has a list of content sources that the extension can use, and some metadata on each source.',
      headers: [
        { text: 'Location', value: 'location', sortable: false },
        { text: 'VirusTotal', value: 'virustotal', sortable: false },
        { text: 'ThreatExchange', value: 'threatexchange', sortable: false },
        { text: 'SSL Labs', value: 'ssllabs', sortable: false }
      ],
      items: {
        title: '',
        values: []
      },
      activeTab: '',
      vt: null,
      cspLookup: null
    }
  },
  watch: {
    activeTab (tab) {
      this.items.title = Object.keys(this.csp)[tab]
      this.items.values = Object.values(this.csp)[tab]
    }
  },
  mounted () {
    this.items.title = Object.entries(this.csp)[0][0]
    this.items.values = Object.entries(this.csp)[0][1]
    this.getVTData()
  },
  methods: {
    submitToVirusTotal () {
      scanCSP(this.csp, this.props.vtapikey)
      setTimeout(function () {
        try {
          this.getVTData()
        } catch (e) {
          console.log('error', e)
        }
      }.bind(this), 4000)
    },
    getVTData () {
      var data = { urls: [] }
      var urlMap = this.parseCSPVT(this.csp)
      Object.entries(urlMap).forEach(([key, value]) => {
        if (value && !data.urls.includes(value)) {
          data.urls.push(value)
        }
      })
      http.post('/virustotal/results', JSON.stringify(data))
        .then(results => {
          return results.data
        }).then(responseData => {
          console.log('here1')
          data = {}
          responseData.map(item => {
            data[item.url] = item.vt
          })
          console.log('here in func')
          this.vt = data
          this.cspLookup = urlMap
        })
        .catch(error => console.log(error))
    },
    parseCSPVT (csp) {
      var urls = []
      for (var key in csp) {
        csp[key].map(url => {
          urls.push(url)
        })
      }
      return this.cleanURLsVT(urls)
    },
    cleanURLsVT (urls) {
      var cleanURLMap = {}
      var myRe = /(https|http|ws|wss):\/\/\*?\.?(\S*)/
      urls.map(url => {
        var results = myRe.exec(url)
        if (results) {
          if (results[1] != 'https' && results[1] != 'http') {
            cleanURLMap[url] = 'https://' + results[2]
          } else {
            cleanURLMap[url] = '' + results[1] + '://' + results[2]
          }
        } else {
          cleanURLMap[url] = null
        }
      })
      return cleanURLMap
    },
    outputVT (line) {
      console.log('here')
      if (this.vt && this.cspLookup) {
        console.log('here2')
        var cleanURL = this.cspLookup[line]
        if (cleanURL && this.vt[cleanURL]) {
          if (this.vt[cleanURL].positives == 0) {
            return `<p style="color:green">${this.vt[cleanURL].positives + ' / ' + this.vt[cleanURL].total}</p>`
          } else if (this.state.vt[cleanURL].positives > 0) {
            return `<p style="color:red">${this.vt[cleanURL].positives + ' / ' + this.vt[cleanURL].total}</p>`
          }
        }
      }
      return 'No data yet'
    }
  }
}
</script>
<style>
.list-fade-enter-to {
  transition: opacity .3s;
  transition-delay: 0.3s;
}
.list-fade-leave-active {
  transition: opacity .3s;
}
.list-fade-enter, .list-fade-leave-to {
  opacity: 0;
}
</style>
