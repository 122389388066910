<template>
  <v-dialog width=350 v-model="showDialog">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="text-none text-body-1 white--text font-weight-bold" color="primary">Create Group</v-btn>
    </template>
    <v-card class="px-8 py-5">
      <template v-if="!groupCreated">
        <v-row class="text-h5">Create Group</v-row>
        <v-row class="py-4">
          <v-text-field label="Enter desired group name" v-model="groupName" hide-details class="pt-0 mt-0"/>
        </v-row>
        <v-row justify='end'>
          <v-btn class="text-none text-body-1 white--text font-weight-bold" color="primary" :disabled="groupName.length < 1" :loading="loading" @click="createGroup(groupName)">Create Group</v-btn>
        </v-row>
      </template>
      <template v-else>
        <v-row class="px-0 py-3 text-h5" align='center' justify='center'>Group created successfully <v-icon large color="primary" class="ml-2">check</v-icon></v-row>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  data () {
    return {
      groupCreated: false,
      groupName: '',
      showDialog: false,
      loading: false
    }
  },
  methods: {
    createGroup (groupName) {
      this.loading = true
      let path = '/group'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      let data = { 'groupName': groupName }
      http.post(path, JSON.stringify(data), { headers: headers })
        .then(response => {
          this.groupCreated = true
          this.$emit('group-created')
        })
        .catch(error => this.$emit('error', error))
        .then(() => { this.loading = false })
    }
  }
}
</script>
