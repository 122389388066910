<template>
  <v-data-table
    class="elevation-2"
    @click:row="viewReport($event)"
    no-data-text="No extensions found for your group"
    :items="allowedExtensions"
    :headers="headers"
    :loading="loading"
    :search="search"
    :sort-by="sortBy"
    :sort-desc="true">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5">Allowed Extensions</v-toolbar-title>
        <v-spacer/>
        <v-text-field
          v-model="search"
          append-icon="search"
          hide-details
          label="Search allowed extensions"
          single-line
          style="max-width: 424px;"
        ></v-text-field>
        <template v-slot:extension>
          <v-row align="center" class="mx-0 text-body-1">
            <v-dialog width="300" v-model="showAllowExtensionDialog">
              <template v-slot:activator="{ on }">
                <v-btn class="text-none text-body-1 primary" v-on="on">Allow An Extension <v-icon class="ml-2">add</v-icon></v-btn>
              </template>
              <v-card class="pa-4 text-h6">
                Enter extension ID to allow
                <v-text-field label="Extension ID" v-model="addedExtensionID"/>
                <v-row justify='end' class="mx-0">
                  <v-btn :disabled="addedExtensionID.length !== 32" class="text-none text-body-1 primary" @click="allowExtension(addedExtensionID)">Allow Extension</v-btn>
                </v-row>
              </v-card>
            </v-dialog>
            <v-spacer/>
            View Options:
            <v-radio-group hide-details row class="ml-3" v-model="viewOption" mandatory>
              <v-radio label="Default" value=''/>
              <v-radio label="Most Installs" value="installs"/>
              <v-radio label="Not In Use" value="unused"/>
              <v-radio label="Highest Risk" value="risk"/>
              <v-radio label="Recently Updated" value="updated"/>
            </v-radio-group>
          </v-row>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-row class="mx-0" align="center" justify="start" @click="viewExtension(item.extension_id)">
        <img height="40px" width="40px" class="mr-2" :src="item.icon" alt=""/>
        {{ item.name }}
      </v-row>
    </template>
    <template v-slot:[`item.users`]="{ item }">
      <v-dialog width="350">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="item.users.sort()" text outlined class="text-none px-2" :disabled="item.users.length === 0">{{ item.users.length }} users</v-btn>
        </template>
        <v-card class="pa-4">
          <v-row class="text-h5 mx-0">Extension Users</v-row>
          <v-card-subtitle class="pa-0">{{ item.name | truncate(40) }}</v-card-subtitle>
          <v-card-subtitle class="px-0">Version {{ item.version }}</v-card-subtitle>
          <v-row class="mx-0">
            <v-col class="grey lighten-4 rounded elevation-1" style="max-height: 300px; overflow-y: scroll;">
              <ul>
                <li v-for="(user, index) in item.users" :key="index">{{ user }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <RemoveExtensionDialog v-on:blockextension="blockExtension(item.extension_id)"/>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="viewReport(item)">
            <v-icon>description</v-icon>
          </v-btn>
        </template>
        <span>View Report</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import RemoveExtensionDialog from '@/components/groups/RemoveExtensionDialog'
import { http } from '@/plugins/axios'

export default {
  components: {
    RemoveExtensionDialog
  },
  data () {
    return {
      addedExtensionID: '',
      showAllowExtensionDialog: false,
      allowedExtensions: [],
      headers: [
        { text: 'Extension Name', value: 'name' },
        { text: 'ID', value: 'extension_id' },
        { text: 'Version', value: 'version' },
        { text: 'Risk', value: 'risk' },
        {
          text: 'Users',
          value: 'users',
          filter: value => this.viewOption !== 'unused' || value.length === 0,
          sort: (a, b) => a.length - b.length
        },
        { text: 'Last Updated', value: 'last_updated' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      viewOption: '',
      search: '',
      loading: true
    }
  },
  props: {
    allowedExtensionCount: Number,
    avgRiskScore: Number,
    groupExtensions: Array
  },
  computed: {
    sortBy () {
      return {
        '': '',
        'installs': 'users',
        'unused': '',
        'risk': 'risk',
        'updated': 'last_updated'
      }[this.viewOption]
    }
  },
  methods: {
    getExtensionsCombined () {
      let path = '/group/extensions/combined'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      return http.get(path, { headers: headers }).catch(error => {
        this.loading = false
        this.$emit('message', [error.message])
        return false
      })
    },
    getExtensionsUsers () {
      let path = '/group/users/extensions'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      return http.get(path, { headers: headers }).catch(error => {
        this.loading = false
        this.$emit('message', [error.message])
        return false
      })
    },
    getExtensions () {
      this.loading = true
      // This triggers both functions, "then" statement is run after both succeed
      Promise.all([this.getExtensionsCombined(), this.getExtensionsUsers()])
        .then((results) => {
          if (!results[0] || !results[1]) return

          let combinedExtensions = results[0].data
          let extensionUsers = results[1].data
          console.log(combinedExtensions)
          console.log(extensionUsers)

          let allowedExtensions = []

          let riskAssessed = 0
          let riskSum = 0

          Object.keys(combinedExtensions).forEach(extensionID => {
            let extension = {
              'extension_id': extensionID,
              'users': []
            }

            if (extensionID in extensionUsers) {
              // Add any users in this group with this extension installed
              Object.entries(extensionUsers[extensionID]).forEach(version => {
                version[1].users.forEach(user => {
                  extension.users.push(user + ': v' + version[0])
                })
                extension.name = version[1].name
              })
            }

            if (extensionID in combinedExtensions) {
              // Adds webstore and risk data for latest version of extension if available
              let combinedExtensionsEntry = combinedExtensions[extensionID]
              let latestVersion = combinedExtensionsEntry[combinedExtensionsEntry.length - 1]
              extension['icon'] = latestVersion.data.webstore.icon
              extension['last_updated'] = latestVersion.data.webstore.last_updated
              extension['name'] = latestVersion.data.webstore.name
              extension['risk'] = latestVersion.data.risk.total
              extension['version'] = latestVersion.version
              riskAssessed += 1
              riskSum += latestVersion.data.risk.total
            }

            allowedExtensions.push(extension)
          })

          let avgRiskScore = (riskSum / riskAssessed).toFixed(0)

          this.$emit('update:allowedExtensionCount', this.groupExtensions.length)
          this.$emit('update:avgRiskScore', Number(avgRiskScore))
          console.log(allowedExtensions)
          this.allowedExtensions = allowedExtensions
          this.loading = false
        })
    },
    allowExtension (extensionID) {
      let path = '/group/extensions/set/' + extensionID
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.post(path, null, { headers: headers })
        .then(response => {
          this.addedExtensionID = ''
          this.showAllowExtensionDialog = false
          this.$emit('message', ['Extension added successfully', 'success'])
        })
        .catch(error => {
          console.log(error)
          let errorText = error.response ? error.response.data.error : error
          this.$emit('message', ['Could not add extension: ' + errorText])
        })
    },
    blockExtension (extensionID) {
      let path = '/group/extensions/set/' + extensionID
      http.delete(path)
        .then(response => {
          this.$emit('message', ['Extension removed successfully', 'success'])
          this.getExtensions()
        })
        .catch(error => {
          console.log(error)
          let errorText = error.response ? error.response.data.error : error
          this.$emit('message', ['Could not remove extension: ' + errorText])
        })
    },
    viewReport (item) {
      this.$router.push({ path: '/report/' + item.extension_id + '/' + item.version })
    }
  },
  watch: {
    showAllowExtensionDialog (val) {
      if (val === false) this.addedExtensionID = ''
    }
  },
  mounted () {
    this.getExtensions()
  }
}
</script>
