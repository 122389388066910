<template>
  <v-card hover :to="'/report/' + extension_id + '?platform=' + extensionPlatform" class="py-3 px-5" style="height: 100%;">
    <v-row justify="center"><v-img height="96" width="96" contain :src="icon"/></v-row>
    <v-row class="title text-truncate d-block">{{ name }}</v-row>
    <v-row class="subtitle-2">{{ short_description | truncate(50)}}</v-row>
    <v-row class="haswhitespace subtitle-1 font-weight-bold">{{ Number(users).toLocaleString() }} users</v-row>
    <v-row>
      <v-rating readonly small dense half-increments background-color="green" color="green" :value="rating"/>(44)
    </v-row>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      slideItemWidth: 100
    }
  },
  props: {
    extension_id: String,
    icon: String,
    name: String,
    short_description: String,
    rating: Number,
    users: Number,
    extensionPlatform: {
      type: String,
      default: 'Chrome'
    }
  }
}
</script>
