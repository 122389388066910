<template>
  <v-container fluid class="py-0 fill-height">
    <v-row v-if="loading" align='center' justify='center' class='fill-height'>
      <v-progress-circular indeterminate color="primary" size=150 />
    </v-row>
    <v-row v-else-if="notFound" align='center' justify='center' class='fill-height'>
      <v-card width="400" height="300" elevation=6 class="px-12">
        <v-row align='center' justify='center' class="fill-height">
          <v-col>
            <v-row class="text-h1 mb-2" justify='center'>404</v-row>
            <v-row class="text-h6 mb-4 text-center">
              Sorry, we couldn't find any data for that extension
            </v-row>
            <v-row justify='center'>
              <v-btn class="text-none text-h6 primary" to="/">Go back</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row v-else>
      <v-col cols=12 lg=4 class="pb-0" :style="{position: ['lg', 'xl'].includes($vuetify.breakpoint.name) ? 'fixed' : 'relative' , overflow: 'scroll'}">
        <v-row>
          <transition name="fade">
            <v-col lg=12 md=8 cols=12>
              <ReportHeader v-bind="report.data.webstore" :extensionPlatform="report.platform" v-if="report.data.webstore"/>
            </v-col>
          </transition>
          <v-col lg=6 md=4 cols=12 class="pt-2">
            <v-row v-if="groupAllowedExtensions.length > 0">
              <v-col class="pt-1">
                <v-card width="100%" :color="allowedForOrganization ? 'green white--text': 'grey lighten-4'" class="text-none text-center text-h6 pa-3" @mouseenter="bruh(this)">{{ allowedForOrganization ? 'Allowed' : 'Not Allowed' }}</v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-1">
                <!-- <v-select outlined hide-details v-model="report.version" :items="availableVersions" label="Version"/> -->
                <v-card width="100%">
                  <v-select outlined hide-details v-model="version" :items="availableVersions" label="Version"/>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-btn class="py-7 text-none text-body-1" width="100%" color="#FFFFFF"
                  :to="'/source/' + $route.params.extension_id + '/' + version + '?platform=' + ($route.query.platform || 'Chrome')">
                  <v-icon left>code</v-icon>
                  Source Code
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ManifestViewer :manifest="report.data.manifest"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=6 v-if="['lg', 'xl'].includes($vuetify.breakpoint.name)">
            <Scrollspy ref="scrollspy" :elements="scrollspyElements"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 lg=8 offset-lg=4 style="overflow: scroll;" class="pa-3">
        <RiskData :risk="report.data.risk" v-if="report.data.risk" v-intersect.quiet="onIntersect" id="risk" key="risk"/>
        <RiskTimeGraph :riskOverTime="riskOverTime" v-if="riskOverTime && riskOverTime.length > 0" v-intersect.quiet="onIntersect" id="riskTime" key="riskTime"/>
        <Permissions :permissions="report.data.manifest.permissions" :optionalPermissions="report.data.manifest.optional_permissions" :permissionWarnings="report.data.webstore.permission_warnings" :platform="report.platform" v-if="report.data.manifest.permissions" v-intersect.quiet="onIntersect" id="permissions" key="permissions"/>
        <ContentSecurityPolicy :csp="report.data.csp" v-if="report.data.csp" v-intersect.quiet="onIntersect" id="csp" key="csp"/>
        <ExternalComm :externalCommsData="report.data.extcalls" v-if="report.data.extcalls" v-intersect.quiet="onIntersect" id="externalComms" key="externalComms"/>
        <RetireJsScan :retireResults="report.data.retire" id="retireJS" key="retireJS"/>
        <OauthScopes v-bind="report.data.manifest.oauth2" v-if="report.data.manifest" id="oauthScopes" key="oauthScopes"/>
        <AllowedRelatedExtensions :relatedExtensions="allowedRelated" :extensionPlatform="report.platform" v-if="allowedRelated && allowedRelated.length > 0" v-intersect.quiet="onIntersect" id="allowedRelatedExtensions" key="allowedRelatedExtensions"/>
        <RelatedExtensions :relatedExtensionsObject="report.data.related" :extensionPlatform="report.platform" v-if="report.data.related" v-intersect.quiet="onIntersect" id="relatedExtensions" key="relatedExtensions"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AllowedRelatedExtensions from '@/components/reports/AllowedRelatedExtensions'
import ContentSecurityPolicy from '@/components/reports/ContentSecurityPolicy'
import ExternalComm from '@/components/reports/ExternalComm'
import ManifestViewer from '@/components/reports/ManifestViewer'
import OauthScopes from '@/components/reports/OauthScopes'
import Permissions from '@/components/reports/Permissions'
import RelatedExtensions from '@/components/reports/RelatedExtensions'
import ReportHeader from '@/components/reports/ReportHeader'
import RetireJsScan from '@/components/reports/RetireJsScan'
import RiskData from '@/components/reports/RiskData'
import RiskTimeGraph from '@/components/reports/RiskTimeGraph'
import Scrollspy from '@/components/Scrollspy.vue'
import { http } from '@/plugins/axios'

export default {
  title: 'CRXcavator',
  components: {
    AllowedRelatedExtensions,
    ContentSecurityPolicy,
    ExternalComm,
    ManifestViewer,
    OauthScopes,
    Permissions,
    RelatedExtensions,
    ReportHeader,
    RetireJsScan,
    RiskData,
    RiskTimeGraph,
    Scrollspy
  },
  data () {
    return {
      webstoreIcon: require('@/assets/images/chrome_webstore_icon.png'),
      responseData: {},
      report: {},
      loading: true,
      notFound: false,
      groupAllowedExtensions: [],
      version: '',
      versionSpecific: false,
      availableVersions: [],
      riskOverTime: [],
      scrollspyElements: [
        { id: 'risk', text: 'Risk' },
        { id: 'permissions', text: 'Permissions' },
        { id: 'csp', text: 'Content Security Policy' },
        { id: 'externalComms', text: 'External Communications' },
        { id: 'retireJS', text: 'RetireJS Results' },
        { id: 'oauthScopes', text: 'OAuth Scopes' },
        { id: 'relatedExtensions', text: 'Related Extensions' }
      ]
    }
  },
  computed: {
    allowedForOrganization () { return this.groupAllowedExtensions.includes(this.extension_id) },
    allowedRelated () {
      let allowedRelated = []
      this.groupAllowedExtensions.forEach(extension => {
        if (this.report.data.related && extension in this.report.data.related) {
          var entry = this.report.data.related[extension]
          entry['extension_id'] = extension
          allowedRelated.push(entry)
        }
      })
      return allowedRelated
    },
    extension_id () { return this.$route.params.extension_id }
  },
  methods: {
    bruh (val) {
      console.log(val)
    },
    getGroupData () {
      let path = '/group'
      let headers = { 'cognito-token': this.$store.state.cognitoUser.signInUserSession.accessToken.jwtToken }
      http.get(path, { headers: headers })
        .then(response => {
          // User is in a group
          this.groupAllowedExtensions = response.data.extensions
        })
        .catch(() => {
          // User is not in a group
          console.log('User not in group')
        })
    },
    getReport (retry) {
      // Is this a problem?
      let path = '/report/' + this.$route.params.extension_id + '?platform=' + (this.$route.query.platform || 'Chrome')

      http.get(path).then(response => {
        if (response.data) {
          this.responseData = {}
          this.availableVersions = response.data.map(item => item.version.split('.').map(n => +n + 10000000).join('.')).sort().reverse()
            .map(item => item.split('.').map(n => +n - 10000000).join('.'))

          response.data.forEach(entry => {
            this.responseData[entry.version] = { data: entry.data, extension_id: entry.extension_id, platform: entry.platform }
          })
          console.log(response.data)

          if (this.$route.params.version) {
            this.version = this.$route.params.version
          } else {
            // Sets version to latest if not provided in path
            this.version = this.availableVersions[0]
          }

          this.setReport(this.version)

          if (response.data.length > 1) {
            let riskChanges = []
            response.data.sort((a, b) => new Date(a.data.webstore.last_updated) - new Date(b.data.webstore.last_updated))
            for (var i in response.data) {
              var riskEntry = {
                x: response.data[i].data.webstore.last_updated,
                y: response.data[i].data.risk.total,
                version: response.data[i].version,
                changes: {}
              }
              if (i != 0) {
                riskEntry['changes']['total'] = response.data[i].data.risk.total - response.data[i - 1].data.risk.total
                riskEntry['changes']['csp'] = (response.data[i].data.risk.csp?.total || 0) - (response.data[i - 1].data.risk.csp?.total || 0)
                riskEntry['changes']['optional_permissions'] = (response.data[i].data.risk.optional_permissions?.total || 0) - (response.data[i - 1].data.risk.optional_permissions?.total || 0)
                riskEntry['changes']['permissions'] = (response.data[i].data.risk.permissions?.total || 0) - (response.data[i - 1].data.risk.permissions?.total || 0)
                riskEntry['changes']['retire'] = (response.data[i].data.risk.retire?.total || 0) - (response.data[i - 1].data.risk.retire?.total || 0)
                riskEntry['changes']['webstore'] = (response.data[i].data.risk.webstore?.total || 0) - (response.data[i - 1].data.risk.webstore?.total || 0)
              }
              riskChanges.push(riskEntry)
            }
            // Only set risk over time if more than one version is tracked
            this.riskOverTime = riskChanges
          }
          this.loading = false
        } else if (this.$route.query.new_scan && retry > 0) {
          // Retries call to API every 3 seconds for a max iterations equal to the initial val of the retry param
          setTimeout(function () {
            this.getReport(retry - 1)
          }.bind(this), 3000)
        } else {
          this.notFound = true
          this.loading = false
          console.log('Cannot find extension report')
        }
      })
    },
    setReport (version) {
      this.report = this.responseData[version]
    },
    parseReport (responseData) {
      // Sets report to report for newest version
      // if (this.$store.state.cognitoUser) {
      //   this.$set(this.report, 'allowedRelated', )
      // }
      let query = Object.assign({}, this.$route.query)
      delete query.new_scan
      this.$router.replace({ query })
    },
    onIntersect (entries, observer, isIntersecting) {
      console.log(entries)
      if (isIntersecting) this.$refs.scrollspy.setActiveElement(entries[0].target.id)
    }
  },
  mounted () {
    this.loading = true
    this.getReport(5)
    if (this.$store.state.cognitoUser) this.getGroupData()
  },
  watch: {
    $route () {
      this.loading = true
      this.getReport(5)
    },
    version (to, from) {
      if (from !== '') {
        this.setReport(to)
      }
    }
  }
}
</script>
