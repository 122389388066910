<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="Requested OAuth2 Scopes" :infoText="infoText">
      <template v-slot:info-text>
        These are the scopes of access that this extension uses to interact with the user's Google account
        <br />
        <v-btn href="https://developers.google.com/identity/protocols/oauth2/scopes" target="_blank" text class="text-none px-0 align-center anchor--text">Learn More About Scopes<v-icon>open_in_new</v-icon></v-btn>
      </template>
    </ReportSectionTopbar>
    <template v-if="scopes && scopes.length > 0">
      <v-row class="text-h6 mx-0 px-4">OAuth2 Client ID: {{ client_id }}</v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-h6 black--text">Scopes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(scope, index) in scopes" :key="index">
              <td>{{ scope }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <template v-else>
      <v-row class="text-h6 pt-4 pb-8" justify='center'>
        This extension does not request any OAuth scopes
      </v-row>
    </template>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'

export default {
  components: {
    ReportSectionTopbar
  },
  data () {
    return {
      infoText: 'These are the scopes of access that this extension uses to interact with the user\'s Google account'
    }
  },
  props: {
    client_id: String,
    scopes: Array
  }
}
</script>
