<template>
  <v-data-table
    :custom-filter="searchFilter"
    disable-sort
    mobile-breakpoint="559"
    :no-data-text="errorText"
    :items="extensions"
    :items-per-page="5"
    :headers="headers"
    :loading="loading"
    :search="search">
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <img height="32px" width="32px" class="mr-2" :src="logo"/>
          <v-toolbar-title class="text-h5">Allowed Extensions</v-toolbar-title>
          <v-spacer/>
          <v-text-field placeholder="Search Extensions" append-icon="search" hide-details v-model="search"/>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.extension="{ item }">
      <v-row class="mx-3" align='center'>
        <img height="42px" width="42px" class="mr-2" :src="item.icon"/>
        <v-col class="py-2">
          <v-row>{{ item.name }}</v-row>
          <v-row>{{ item.extension_id }}</v-row>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>
<script>
import { http } from '@/plugins/axios'

export default {
  title: 'CRXcavator',
  data () {
    return {
      extensions: [],
      headers: [
        // { text: 'Hummus', value: 'name' },
        { text: 'Extension', value: 'extension' }
      ],
      errorText: 'No data found using the given Dashboard ID',
      search: '',
      loading: true,
      logo: require('@/assets/images/crxcavator_logo.png')
    }
  },
  methods: {
    getDashboardContents (dashboardID) {
      let path = '/embed/dashboard/' + dashboardID
      http.get(path)
        .then(response => {
          if (response.data !== null) {
            response.data.sort((itemA, itemB) => (itemA.name > itemB.name) ? 1 : -1)
            this.extensions = response.data
          }
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
          this.errorText = 'Error retrieving data from CRXcavator'
        })
        .then(() => { this.loading = false })
    },
    searchFilter (value, search, item) {
      return search != null && (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.extension_id === search)
    }
  },
  mounted () {
    console.log(this.$route.params.dashboard_id)
    this.getDashboardContents(this.$route.params.dashboard_id)
  }
}
</script>
