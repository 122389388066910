<template>
  <v-card>
    <v-col>
      <v-row class="px-3 text-h5">{{ retireResult.component }} v{{ retireResult.version }}</v-row>
      <v-row class="px-3 mb-2">
        <!-- File Path: {{ retireResult.file }} -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">File Path: {{ retireResult.file }}</span>
          </template>
          <span>{{ retireResult.full_path }}</span>
        </v-tooltip>
      </v-row>
      <v-row :class="'d-inline-block rounded-r-xl px-3 mb-2 text-h6 white--text text-capitalize ' + severityColor">
        {{ retireResult.severity }} Severity
      </v-row>
      <v-row class="px-3 mb-2 text-body-1" v-if="retireResult.identifiers.summary">{{ retireResult.identifiers.summary }}</v-row>
      <v-row class="px-3 mb-2 text-body-1" v-if="retireResult.identifiers.CVE">{{ retireResult.identifiers.CVE[0] }}</v-row>
      <v-row class="px-3">
        <v-dialog max-width=350>
          <template v-slot:activator="{ on }">
            <v-btn class="text-none" v-on="on">Learn More</v-btn>
          </template>
          <v-card class="px-6 py-3">
            <v-row class="text-h6">Find out more about this issue at the following links</v-row>
            <v-row v-for="(link, index) in retireResult.info" :key="index" class="my-1" style="font-size: 18px;">
              <a :href="link" target="blank">{{ link | hostname }}</a>
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
export default {
  props: {
    retireResult: Object
  },
  computed: {
    severityColor () {
      return { 'high': 'red', 'medium': 'orange darken-3', 'low': 'yellow darken-2' }[this.retireResult.severity]
    }
  },
  filters: {
    hostname (value) {
      let parsedURL = new URL(value)
      return parsedURL.hostname
    }
  },
  mounted () {
    console.log(this.retireResult)
  }
}
</script>
