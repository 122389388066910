import axios from 'axios'
import store from '@/store.js'

const http = axios.create({
  // withCredentials: true,
  baseURL: process.env.VUE_APP_CRXCAVATOR_URL
})

// Adds cognito token to all requests
http.interceptors.request.use(function (config) {
  if (store.state.cognitoUser) {
    config.headers['cognito-token'] = store.state.cognitoUser.signInUserSession.accessToken.jwtToken
  }
  return config
})

export { http }
