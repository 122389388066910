<template>
  <v-card class="mt-3 my-6">
    <ReportSectionTopbar titleText="Permissions" :infoText="infoText"/>
    <v-row class="px-3 pt-1 pb-2" v-if="permissionWarnings && permissionWarnings.length > 0">
      <v-col cols=12 class="py-1">
        <a target="_blank" href="https://developer.chrome.com/extensions/permission_warnings#permissions_with_warnings">Google produces warnings</a> about the following access this extension holds:
      </v-col>
      <ul class="px-4 mx-6">
        <li v-for="(warning, index) in permissionWarnings" :key="index"><strong>{{ warning }}</strong></li>
      </ul>
    </v-row>
    <v-row v-if="permissions && permissions.length > 0">
      <v-col class="py-0">
        <v-data-table disable-pagination hide-default-footer :headers="headers" :items="formattedPermissions" sort-by="risk">
          <template v-slot:item.description="{ item }">
            <v-col class="pa-0" v-html="item.description" />
          </template>
          <template v-slot:item.risk="{ item }">
            <v-chip :class="riskColors[item.risk]">{{ item.risk }}</v-chip>
          </template>
          <template v-slot:item.optional="{ item }">
            <v-icon v-if="item.optional" color="textcolor">check</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-else class="text-h6 pt-4 pb-8" justify='center'>
      This extension does not require any permissions
    </v-row>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'
const permissionReference = require('@/assets/permission_reference.json')

export default {
  components: {
    ReportSectionTopbar
  },
  props: {
    permissions: Array,
    optionalPermissions: {
      type: Array,
      default: () => []
    },
    permissionWarnings: {
      type: Array,
      default: () => []
    },
    platform: {
      type: String,
      default: 'Chrome'
    }
  },
  data () {
    return {
      permissionDescriptions: permissionReference[this.platform].permission_descriptions,
      permissionRiskMapping: permissionReference[this.platform].permission_risk,
      infoText: 'This section outlines the permissions this extension has when installed in your browser. The extension requires all permissions that are not specifically marked as optional.',
      headers: [
        { text: 'Permission', value: 'permission', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Risk', value: 'risk' },
        { text: 'Optional', value: 'optional', align: 'end' }
      ],
      riskColors: {
        'Critical': 'black white--text',
        'High': 'red white--text',
        'Medium': 'orange darken-2 white--text',
        'Low': 'blue white--text',
        'None': 'green white--text',
        'Unknown': ''
      }
    }
  },
  computed: {
    formattedPermissions () {
      var formattedPermissions = []
      for (var permission of this.permissions) {
        formattedPermissions.push({
          'permission': permission,
          'description': this.permissionDescriptions[permission] || '',
          'risk': this.permissionRiskMapping[permission] || 'Unknown',
          'optional': false
        })
      }
      for (var optionalPermission of this.optionalPermissions) {
        formattedPermissions.push({
          'permission': optionalPermission,
          'description': this.permissionDescriptions[optionalPermission] || '',
          'risk': this.permissionRiskMapping[optionalPermission] || 'Unknown',
          'optional': true
        })
      }
      return formattedPermissions
    }
  }
}
</script>
