<template>
  <v-tabs vertical :value="activeTab" class="elevation-3">
    <v-tab v-for="(element, index) in elements" :key="index" @click="goTo('#' + element.id)" class="black--text" active-class="primary--text">{{ element.text }}</v-tab>
  </v-tabs>
</template>
<script>
export default {
  data () {
    return {
      activeTab: 0,
      autoMove: false,
      options: {
        offset: 15
      }
    }
  },
  props: {
    elements: Array
  },
  computed: {
    elementsArray () {
      return this.elements.map(element => element.id)
    }
  },
  methods: {
    goTo (id) {
      this.autoMove = true
      this.$vuetify.goTo(id, this.options)
      setTimeout(function () { this.autoMove = false }.bind(this), 500)
    },
    setActiveElement (element) {
      if (!this.autoMove) {
        this.activeTab = this.elementsArray.indexOf(element)
      }
    }
  }
}
</script>
<style scoped>
.scrollspy_item_active {
  text-decoration: underline;
  transition: color 0.3s;
}
.v-tabs >>> .v-item-group {
  width: 100%;
}
.v-tab {
  padding: 0;
}
</style>
