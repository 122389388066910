<template>
  <v-app-bar app dense color="background" elevation=1>
    <v-col class="pa-0 d-lg-none">
      <v-app-bar-nav-icon @click="$emit('update:sidebar', !sidebar)"/>
    </v-col>
    <v-col class="d-none d-lg-flex pa-0" style="height: 100%;">
      <v-btn v-for="(link, index) in links" :key="index" :to="link.link" class="text-none" style="height: 100%;" text>{{ link.text }}</v-btn>
    </v-col>
    <v-col class="py-0 flex-grow-0">
      <v-row justify='center' align='center' class='headline flex-nowrap' v-if='$route.path !== "/"'>
        <router-link to="/" style="text-decoration:none">
          <img style="object-fit: contain;" height="32px;" class="px-1" :src="logo"/>
        </router-link>
        <router-link to ="/" style="color:#000; text-decoration:none">CRXcavator</router-link>
      </v-row>
    </v-col>
    <!-- <v-col cols=8 lg=3 offset-lg=1 class="py-0 fill-height"> -->
    <v-col class="py-0 fill-height">
      <v-row justify='end' align='center' class="d-none d-md-flex fill-height">
        <!-- <v-col cols=8 class="py-0">
          <SearchBar/>
        </v-col> -->
        <SearchBarSmall v-if='$route.path !== "/"'/>
        <v-menu offset-y open-on-hover v-if="authStatus === 'SIGNED_IN'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" active-class="" class="text-none align-center" style="height: 100%;">
              <v-icon class="mr-2">account_circle</v-icon>
              <div id="userheader" class="text-body-1">{{ $store.state.cognitoUser.signInUserSession.idToken.payload.email }}</div>
              <!-- <div class="caption">Administrator</div> -->
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/settings">
              <v-list-item-title class="text-center">User Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title class="text-center">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog max-width=400 v-model="authenticatorShown" v-else-if="$route.name !== 'login'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="text-none" v-bind="attrs" v-on="on">Login/Register</v-btn>
          </template>
          <Authenticator v-on:close-window="authenticatorShown = false"/>
        </v-dialog>
        <!-- <Authenticator v-else/> -->
      </v-row>
    </v-col>
    <!-- <v-spacer v-if="$route.path == '/'"/> -->
  </v-app-bar>
</template>
<script>
// import router from '@/router'
import Authenticator from '@/components/cognito/Authenticator.vue'
import { Auth } from '@aws-amplify/auth'
import SearchBarSmall from '@/components/SearchBarSmall.vue'

export default {
  components: {
    Authenticator,
    SearchBarSmall
  },
  data () {
    return {
      authenticatorShown: false,
      logo: require('@/assets/images/crxcavator_logo.png')
    }
  },
  props: {
    sidebar: Boolean
  },
  computed: {
    authStatus () {
      return this.$store.state.authStatus
    },
    links () {
      var links = [
        { text: 'Home', link: '/' },
        { text: 'Docs', link: '/docs' },
        { text: 'API Docs', link: '/apidocs' }
      ]
      if (this.$store.state.authStatus === 'SIGNED_IN' && this.$store.state.inGroup === true) {
        links.push({ text: 'Dashboard', link: '/dashboard' })
      }
      return links
    }
  },
  methods: {
    logout () {
      Auth.signOut()
      this.$store.dispatch('signOut').then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>
<style>
#userheader * {
  line-height: normal;
}
</style>
