<template>
  <v-card class="px-3 pb-2">
    <v-row align="center">
      <v-col cols=12 md=3>
        <v-row justify='center'>
          <v-img height="84" width="84" :src="icon" contain></v-img>
        </v-row>
      </v-col>
      <v-col :class="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'px-6' : ''">
        <v-row><v-card-title class="text-h4 pa-0 wordbreak-normal" style="font-size: 1.875rem !important">{{ name }}</v-card-title></v-row>
        <v-row><v-card-subtitle class="pa-0">{{ short_description | striphtml }}</v-card-subtitle></v-row>
      </v-col>
    </v-row>
    <v-card-text class="py-0 subtitle-1">
      <v-row>
        <v-col class="py-1">
          <v-row class="subtitle-1 haswhitespace" v-if="extensionPlatform !== 'Edge'">This extension has <strong>{{ Number(users).toLocaleString() }} users</strong></v-row>
          <v-row class="subtitle-1 haswhitespace">It has <strong>{{ Number(rating_users).toLocaleString() }} webstore reviews</strong></v-row>
          <v-row class="subtitle-1 haswhitespace" v-if="rating_users > 0">This extension's average webstore rating is <strong>{{ rating.toString().slice(0,3) }}/5</strong></v-row>
          <v-row class="subtitle-1 haswhitespace">
            <v-btn :href="privacy_policy" target="_blank" v-if="privacy_policy && privacy_policy.length > 0" text small class="text-none subtitle-1 px-2 ml-n2">View Privacy Policy <v-icon class="ml-1" small>open_in_new</v-icon></v-btn>
            <template v-else>This extension has <strong>no privacy policy</strong></template>
          </v-row>
        </v-col>
        <v-col class="flex-grow-0 py-1 pr-0">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :href="webstoreLink" target="_blank" height="48px" width="48px" min-width="48px">
                <v-img height="48px" width="48px" contain :src="webstoreIcon"/>
              </v-btn>
            </template>
            <span>View on {{ extensionPlatform }} Webstore</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="my-2">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0 subtitle-1">Developer Info</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mb-2" v-if='website'><v-btn class="text-none" depressed :href="website" target="_blank">Developer Website</v-btn></v-row>
              <v-row class="mb-2" v-else><v-btn class="text-none" disabled>No Developer Website Listed</v-btn></v-row>
              <v-row v-if='website'><v-btn class="text-none" depressed :href="support_site" target="_blank">Support Website</v-btn></v-row>
              <v-row v-else><v-btn class="text-none" disabled>No Support Website Listed</v-btn></v-row>
              <v-row v-if="extensionPlatform === 'Chrome'">
                <v-col class="py-1">
                  <v-row class="overline">Address</v-row>
                  <v-row class="subtitle-1">{{ address.length > 0 ? address : "Not listed" }}</v-row>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col class="py-1">
                  <v-row class="overline">Email</v-row>
                  <v-row class="subtitle-1">{{ email.length > 0 ? email : "Not listed" }}</v-row>
                </v-col>
              </v-row>
              <v-divider/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row class="caption">LAST UPDATED {{ last_updated }}<v-spacer/>SIZE: {{ size }}</v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  computed: {
    webstoreLink () {
      var webstoreURLs = {
        'chrome': 'https://chrome.google.com/webstore/detail/',
        'firefox': 'https://addons.mozilla.org/en-US/firefox/addon/',
        'edge': 'https://microsoftedge.microsoft.com/addons/detail/'
      }
      return webstoreURLs[this.extensionPlatform.toLowerCase()] + this.$route.params.extension_id
    },
    webstoreIcon () {
      return require('@/assets/images/' + this.extensionPlatform.toLowerCase() + '_webstore_icon.png')
    }
  },
  props: {
    address: String,
    email: String,
    extensionPlatform: { type: String, default: 'Chrome' },
    icon: String,
    last_updated: String,
    name: String,
    privacy_policy: String,
    rating: Number,
    rating_users: Number,
    short_description: String,
    size: String,
    support_site: String,
    users: Number,
    website: String
  }
}
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
/* .v-expansion-panel * {
  color: rgba(0, 0, 0, 0.6)
} */
</style>
