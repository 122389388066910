<template>
  <v-snackbar top light v-model="showSnackbar" :color="toastColor">
    <v-row class="py-0 px-3 text-h6" justify='center'>{{ message }}</v-row>
  </v-snackbar>
</template>
<script>
export default {
  data () {
    return {
      toastColor: '',
      message: '',
      showSnackbar: false
    }
  },
  methods: {
    showMessage (message, messageType = 'error', persistent = false) {
      this.message = message
      if (messageType === 'error') {
        this.toastColor = 'red lighten-4'
      } else if (messageType === 'success') {
        this.toastColor = 'green lighten-4'
      } else {
        this.toastColor = ''
      }
      this.showSnackbar = true
      if (!persistent) {
        setTimeout(() => {
          this.showSnackbar = false
          this.message = ''
        }, 3000)
      }
    }
  }
}
</script>
