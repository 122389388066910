<template>
  <v-stepper v-model="signUpStep">
    <MessageToast ref="messageToast"/>
    <v-stepper-items>
      <v-stepper-content step=1>
        <v-col class="py-0">
          <v-row class="text-h4 mb-2">Create an Account</v-row>
          <v-row>
            <v-text-field prepend-icon="person" label="Email Address" v-model="userEmail"/>
          </v-row>
          Passwords must be
          <ul>
            <li>Be at least 16 characters long</li>
            <li>Contain at least 1 uppercase letter</li>
            <li>Contain at least 1 lowercase letter</li>
            <li>Contain at least 1 special character</li>
          </ul>
          <v-row>
            <v-text-field
              label="Password"
              prepend-icon="vpn_key"
              :rules="passwordRules"
              type="password"
              v-model="userPassword"
              validate-on-blur/>
          </v-row>
          <v-row>
            <v-text-field
              label="Re-enter your password"
              prepend-icon="vpn_key"
              :rules="passwordMatchRule"
              type="password"
              v-model="userPasswordConfirm"
              validate-on-blur/>
          </v-row>
          <v-row justify='end' class="mb-3">
            <v-btn
              class="text-none text-body-1 font-weight-bold white--text"
              @click='signUpStep += 1'
              color="primary"
              :disabled="userEmail.length < 1 || userPassword.length < 1 || userPasswordConfirm.length < 1 || userPassword != userPasswordConfirm"
              large>
              Next
            </v-btn>
          </v-row>
          <v-row justify='end'>
            <v-btn text class="px-0 text-none text-decoration-underline" @click="signUpStep = 3">Confirm a Previous Registration</v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=2>
        <v-col class="py-0">
          <v-row class="text-h4 mb-4">Terms of Service</v-row>
          <v-row>
            <v-col class="grey lighten-4 rounded elevation-1" style="max-height: 300px; overflow-y: scroll;">
              UPDATED MAY 28 2019<br />
              <strong>1 )</strong> CRXcavator (including the CRXcavator Gatherer extension) is a proprietary service developed exclusively by Duo Security, Inc. (“Duo Security”). Duo Security grants you a non-exclusive, non-sublicensable, non-transferable license to access and use CRXcavator, as updated and modified by Duo Security from time to time in its sole discretion, for non-commercial purposes. For purposes of this Agreement, “You” shall mean the individual or legal entity accessing or using the CRXcavator service.<br />
              <strong>2 )</strong> If you register an account with CRXcavator, you may be required to use Duo Security’s multi-factor authentication service in order to login to the CRXcavator service. Your access to and use of Duo Security’s multi-factor authentication service in connection with CRXcavator is subject to and, by registering an account with CRXcavator, you expressly agree to the terms and conditions applicable to the Duo Security multi-factor authentication service located at https://duo.com/legal/terms. Notwithstanding the foregoing, the CRXcavator service itself is not part of any Duo Security “Services” as defined in the terms located at https://duo.com/legal/terms or any similar commercial terms and conditions applying to Duo Security’s multi-factor authentication services.<br />
              <strong>3 )</strong> You will not: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas or algorithms of CRXcavator or any data related thereto (except to the extent such prohibition is contrary to any applicable law that cannot be excluded by the agreement of the parties); modify, translate, or create derivative works based on CRXcavator; share, rent, lease, loan, resell, sublicense, distribute, use or otherwise transfer CRXcavator for timesharing or service bureau purposes or for any purpose other than your own use; or use CRXcavator other than in accordance with these terms of use and in compliance with all applicable laws.<br />
              <strong>4 )</strong> Except as expressly set forth herein, Duo Security alone will retain all intellectual property rights relating to CRXcavator or any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you relating to CRXcavator, which are hereby assigned to Duo Security. You will not copy, distribute, reproduce or use any of the foregoing except as expressly permitted under this Agreement.<br />
              <strong>5 )</strong> You acknowledge that if you register an account with CRXcavator, you will be required to share with Duo Security certain information which may include personal data related to both you and any individuals you enroll in CRXcavator, including via the CRXcavator Gatherer extension (e.g. Chrome usernames/email addresses). The CRXcavator service stores this information in an AWS environment located in the United States. Prior to enrolling any individuals in CRXcavator, You are fully responsible for notifying, and/or obtaining any legally required consent of, such individual(s) in accordance with applicable data protection laws regarding the use of his/her information by Duo Security, which use is described in Duo Security’s Services Privacy Notice, located at https://duo.com/legal/privacy-notice-services. You represent and warrant that any consents required by applicable data protection laws have been or will be obtained prior to enrolling any individual(s) in CRXcavator.<br />
              <strong>6 )</strong> Duo Security may terminate the license granted to you in Section 1 of these terms of use at any time without notice.<br />
              <strong>7 )</strong> DISCLAIMER: CRXCAVATOR, INCLUDING THE SITE AND ANY DATA PROVIDED IN CONNECTION THEREWITH, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND DUO SECURITY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT DUO SECURITY DOES NOT WARRANT THAT CRXCAVATOR WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY OR REPRESENTATION AS TO THE DATA OR RESULTS THAT MAY BE OBTAINED FROM USE OF CRXCAVATOR, AND NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM DUO SECURITY OR THROUGH CRXCAVATOR SHALL CREATE ANY WARRANTY OR OTHER OBLIGATION BY OR FROM DUO SECURITY. YOU FURTHER ACKNOWLEDGE AND AGREE THAT BECAUSE CRXCAVATOR IS PROVIDED TO YOU FREE OF CHARGE, DUO SECURITY SHALL HAVE NO LIABILITY TO YOU IN ANY FORM WHATSOEVER RELATED TO YOUR USE OF OR ACCESS TO CRXCAVATOR (WHETHER BASED ON CONTRACT, TORT (INCLUDING ACTIVE AND PASSIVE NEGLIGENCE AND STRICT LIABILITY)) OR OTHERWISE. YOU HEREBY AGREE TO INDEMNIFY AND DEFEND DUO SECURITY AGAINST ANY AND ALL THIRD PARTY CLAIMS (INCLUDING, BUT NOT LIMITED TO, ANY LOSSES, COSTS, EXPENSES AND ATTORNEYS' FEES) MADE AGAINST DUO SECURITY RELATING IN ANY WAY TO YOUR USE OF CRXCAVATOR.<br />
              <strong>8 )</strong> These terms of use will be governed by the laws of the State of California, U.S.A. without regard to its conflict of laws provisions.
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox color="primary" v-model="tosAgree" label="I agree to the above terms and conditions" />
          </v-row>
          <v-row justify='end'>
            <v-btn
              @click='signUp()'
              class="text-none text-body-1 font-weight-bold white--text"
              color="primary"
              :disabled="!tosAgree"
              large
              :loading="signUpLoading">
              Register
            </v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=3>
        <v-col class="py-0">
          <v-row class="text-h4 mb-4">Confirm Registration</v-row>
          <v-row>
            <v-text-field prepend-icon="person" label="Email Address" v-model="userEmail"/>
          </v-row>
          <v-row class="mb-2">
            <v-text-field v-mask="'######'" prepend-icon="mdi-lock-check" label="Confirmation Code" v-model="code"/>
          </v-row>
          <v-row justify='space-between'>
            <v-btn @click='reSendCode()' :disabled="userEmail.length < 1" class="text-none text-body-1" large>Re-Send Code</v-btn>
            <v-btn @click='confirmSignUp()' color="primary" :disabled="userEmail.length < 1 || code.length !== 6" class="text-none text-body-1 font-weight-bold white--text" large>Confirm</v-btn>
          </v-row>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step=4>
        <v-row class="px-0 py-3 text-h5" align='center' justify='center'>Registration successful <v-icon large color="primary" class="ml-2">check</v-icon></v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { Auth } from '@aws-amplify/auth'
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask
  },
  data () {
    return {
      signUpStep: 1,
      signUpLoading: false,
      confirmSignUpLoading: false,
      userPasswordConfirm: '',
      userPassword: '',
      userEmail: '',
      user: null,
      code: '',
      passwordRules: [
        () => this.userPassword.length >= 16 || 'Password must be more than 16 characters',
        () => /[a-z]/.test(this.userPassword) || 'Password must contain a lowercase letter',
        () => /[A-Z]/.test(this.userPassword) || 'Password must contain an uppercase letter',
        () => /[=+-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(this.userPassword) || 'Password must contain a special character'
      ],
      passwordMatchRule: [
        () => this.userPassword === this.userPasswordConfirm || 'Passwords must match'
      ],
      tosAgree: false
    }
  },
  methods: {
    async signUp () {
      this.signUpLoading = true
      try {
        const { user } = await Auth.signUp({
          username: this.userEmail, // should be an email
          password: this.userPassword
        })
        this.$store.commit('storeCognitoUser', { user: user })
        this.signUpLoading = false
        this.signUpStep = 3
      } catch (error) {
        this.signUpLoading = false
        this.signUpStep = 1
        this.$refs.messageToast.showMessage(error.message)
      }
    },
    async confirmSignUp () {
      this.confirmSignUpLoading = true
      Auth.confirmSignUp(this.userEmail, this.code)
        .then(data => {
          console.log(data)
          this.signUpStep = 4
          setTimeout(() => this.$store.commit('storeAuthStatus', { authStatus: 'SIGN_IN' }), 3000)
        })
        .catch(err => {
          this.$refs.messageToast.showMessage(err.message)
        })
        .then(() => { this.confirmSignUpLoading = false })
    },
    async reSendCode () {
      Auth.resendSignUp(this.userEmail)
        .then(data => {
          this.$refs.messageToast.showMessage('New code sent', 'notification')
          console.log(data)
        })
        .catch(err => {
          this.$refs.messageToast.showMessage(err.message)
        })
    }
  }
}
</script>
