<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="Risk" :infoText="infoText"/>
    <v-row>
      <v-col cols=12 md=6 class="order-md-2">
        <VueApexCharts ref="apexchart" width="100%" type="donut" :options="options" :series="series"/>
      </v-col>
      <v-col cols=12 md=6 class="order-md-1">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Source of Risk</th>
                <th class="text-left">Risk Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-on:mouseover="toggleChartItem(index)" v-on:mouseout="toggleChartItem(index)" v-for="(item, index) in tableEntries" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ item.score }}</td>
              </tr>
              <tr style="background-color: rgba(0, 0, 0, 0.08)" class="font-weight-bold">
                <td>Total Risk Score</td>
                <td>{{ risk.total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    ReportSectionTopbar,
    VueApexCharts
  },
  methods: {
    toggleChartItem (index) {
      this.$refs.apexchart.toggleDataPointSelection(index)
    }
  },
  props: {
    risk: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      infoText: 'This section outlines the level of risk associated with each component of the extension that CRXcavator evaluates. A comprehensive risk score is listed at the bottom.',
      options: {
        colors: ['#0B5345', '#186A3B', '#1D8348', '#239B56', '#2ECC71', '#82E0AA'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          }
        },
        chart: {
          id: 'riskdata-chart'
        },
        states: {
          active: {
            filter: {
              type: 'lighten',
              value: 0.2
            }
          }
        },
        labels: [
          'Content Security Policy',
          'External Calls',
          'Optional Permissions',
          'Permissions',
          'Vulnerability Scan Results',
          'Webstore Details'
        ]
      },
      headers: [
        { text: 'Source of Risk', value: 'title', sortable: false },
        { text: 'Risk Score', value: 'score', sortable: false }
      ]
      // items: [
      // ]
    }
  },
  computed: {
    series () {
      return [
        this.risk.csp?.total || 0,
        this.risk.extcalls?.total || 0,
        this.risk.permissions?.total || 0,
        this.risk.optional_permissions?.total || 0,
        this.risk.retire?.total || 0,
        this.risk.webstore?.total || 0
      ]
    },
    tableEntries () {
      return [
        {
          title: 'Content Security Policy',
          id: 'contentsecuritypolicy',
          score: this.risk.csp?.total || 0
        },
        {
          title: 'External Calls',
          id: 'extcalls',
          score: this.risk.extcalls?.total || 0
        },
        {
          title: 'Permissions',
          id: 'permissions',
          score: this.risk.permissions?.total || 0
        },
        {
          title: 'Optional Permissions',
          id: 'optionalpermissions',
          score: this.risk.optional_permissions?.total || 0
        },
        {
          title: 'Vulnerability Scan Results',
          id: 'retirejs',
          score: this.risk.retire?.total || 0
        },
        {
          title: 'Webstore Details',
          id: 'webstoredetails',
          score: this.risk.webstore?.total || 0
        }
      ]
    }
  }
}
</script>
