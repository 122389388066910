<template>
  <v-dialog width=600>
    <template v-slot:activator="{ on }">
      <v-btn class="py-7 text-none text-body-1" width="100%" color="#FFFFFF" v-on="on">
        <v-icon left>description</v-icon>
        Manifest
      </v-btn>
    </template>
    <v-card class="px-6 py-3">
      <v-row class="text-h5 justify-center mb-3">Extension Manifest</v-row>
      <v-row class="pa-2 grey lighten-3 rounded">
        <vue-json-pretty
          :deep="1"
          :path="'res'"
          :data="manifest">
        </vue-json-pretty>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty
  },
  props: {
    manifest: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style>
.vjs-tree .vjs-value__string {
  color: #C62828;
}
.vjs-key {
  font-weight: bold;
}
</style>
