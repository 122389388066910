<template>
  <iframe id="duoIframe" height="500" style="width: 100%;"/>
</template>
<script>
import { Auth } from '@aws-amplify/auth'
import { http } from '@/plugins/axios'
import DuoWebSDK from 'duo_web_sdk'

export default {
  name: 'DuoAuth',
  data () {
    return {
      user: this.$store.state.cognitoUser
    }
  },
  mounted () {
    DuoWebSDK.init({
      'host': 'api-4f032fed.duosecurity.com',
      'sig_request': this.user.challengeParam.sig_request,
      'submit_callback': this.duoAuthSuccess,
      'iframe': 'duoIframe'
    })
  },
  methods: {
    async duoAuthSuccess (form) {
      try {
        Auth.sendCustomChallengeAnswer(this.user, form.sig_response.value)
          .then(user => {
            console.log(user)
            this.$store.commit('storeCognitoUser', { user: user })
            this.$store.commit('storeAuthStatus', { authStatus: 'SIGNED_IN' })
            this.$emit('duo-authed')
            http.get('/group', { headers: { 'cognito-token': user.signInUserSession.accessToken.jwtToken } })
              .then(response => { this.$store.commit('storeGroupStatus', { inGroup: true }) })
              .catch(error => { console.log(error) })
          })
          .catch(err => console.log(err))
      } catch (error) {
        console.log('error signing in:', error)
      }
    }
  }
}
</script>
