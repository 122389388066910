import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MessageToast from '@/components/MessageToast'
import Amplify, * as AmplifyModules from '@aws-amplify/core'
import VueAnalytics from 'vue-analytics'
import titleMixin from './mixins/titleMixin'
// import { AmplifyPlugin } from 'aws-amplify-vue'

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_kZLGytl9E',
    userPoolWebClientId: '4u8ni5nr3a66n7vnd5orspnqfp',
    mandatorySignIn: true,
    authenticationFlowType: 'CUSTOM_AUTH'
  }
})

Vue.mixin(titleMixin)

Vue.use(AmplifyModules)
window.LOG_LEVEL = 'DEBUG'

Vue.config.productionTip = false

Vue.filter('truncate', function (string = '', length = 20) {
  return string.length <= length ? string : string.slice(0, length - 3) + '...'
})

Vue.filter('striphtml', function (value) {
  var div = document.createElement('div')
  div.innerHTML = value
  var text = div.textContent || div.innerText || ''
  return text
})

Vue.component('MessageToast', MessageToast)

Vue.use(VueAnalytics, {
  id: 'UA-118326895-1'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
