<template>
  <v-card class="mt-3 mb-6">
    <ReportSectionTopbar titleText="External Communications" :infoText="infoText" />
    <v-data-table
      :headers="headers"
      :items='externalComms'
      no-data-text="No external communications were found in this extension's source code"
      :footer-props="{ 'disable-items-per-page': true }">
      <template v-slot:item.paths="{ item }">
        <v-col class="pa-0">
          <v-chip style="margin-right: 2px;" v-for="(path, index) in item.paths" :key="index">{{ path }}</v-chip>
        </v-col>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import ReportSectionTopbar from '@/components/reports/ReportSectionTopbar'

export default {
  components: {
    ReportSectionTopbar
  },
  props: {
    externalCommsData: Array
  },
  data () {
    return {
      infoText: 'This section lists all external URLs that were detected in the source code of the extension. The extension may communicate with any of these URLs, but will not necessarily do so.',
      headers: [
        { text: 'Domain', value: 'domain' },
        { text: 'Paths', value: 'paths' }
      ]
    }
  },
  computed: {
    externalComms () {
      var externalComms = {}
      var externalCommsData = Array.from(new Set(this.externalCommsData))
      externalCommsData.forEach(entry => {
        try {
          var url = new URL(entry)
          if (url.hostname in externalComms && !externalComms[url.hostname].includes(url.pathname)) externalComms[url.hostname].push(url.pathname)
          else externalComms[url.hostname] = [url.pathname]
        } catch (err) {
        }
      })
      return Object.entries(externalComms).map(entry => { return { 'domain': entry[0], 'paths': entry[1] } })
    },
    pageCount () {
      return Math.ceil(this.externalComms.length / 10)
    }
  }
}
</script>
